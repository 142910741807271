import React from 'react'
import Form from '../../../../../components/form/index.jsx'
import { patchReagendarEvents } from '../../../../../api/calendar.js'
import BlueResumeUI from '../../../../../components/BlueResumeUI/BlueResumeUI.jsx'
import Br from '../../../../../components/br/Br.jsx'
import LabelDrawer from '../../../../../components/text/drawer/label/index.jsx'
import { Input } from 'antd'
import Select from 'react-select'
import SubtitleBlue from '../../../../../components/text/drawer/subtitleBlue/index.jsx'
import MainTitle from '../../../../../components/text/drawer/mainTitle/index.jsx'
import './index.scss'
import dayjs from 'dayjs'
import { startCase } from 'lodash';
import BottomSection from '../../../../../components/form/bottomSection/index.jsx'


const ReScheduleForm = ({ storedOperatorID, osID, reagendarEdit, closeResumeForm, overflowavAilability }) => {
	const isDisabled = true;

	// si es despues de las 12:00, retorna true
	const amOrPm = (value) => dayjs(value, "HH:mm").isAfter(dayjs("12:00", "HH:mm"));
	const formatDay = (value) => {
		const date = dayjs(value, "DD-MM-YYYY");
		return date.format("dddd D MMMM YYYY").replace(/(\w+)/g, startCase);
	}

	const options = () => {
		return {
			value: 1,
			label: reagendarEdit?.technician
		}
	}

	const customStyles = {
		multiValue: (base) => ({
			...base,
			backgroundColor: "white",
			color: '#5A607F',
			border: '1px solid #5A607F',
			borderRadius: "2px",
			fontSize: '12px'
		}),
		multiValueLabel: (base) => ({ ...base, color: '#5A607F' }),
		multiValueRemove: (base, _) => ({
			...base,
			backgroundColor: 'white',
			color: '#5A607F',
			':hover': { backgroundColor: "white" },
		}),
		control: (base, state) => ({
			...base,
			backgroundColor: isDisabled ? 'white' : base.backgroundColor,
			borderColor: state.isFocused ? '#4096ff' : '#d9d9d9',
			boxShadow: state.isFocused ? 'none' : base.boxShadow,
		})
	};


	const patchEvent = () => {

		if (
			reagendarEdit?.technicianID === null ||
			reagendarEdit?.disponibilidad === null ||
			reagendarEdit?.start === null ||
			reagendarEdit?.end === null

		) {
			return
		}

		patchReagendarEvents(
			storedOperatorID,
			reagendarEdit?.date,
			osID,
			reagendarEdit?.technicianID,
			reagendarEdit?.disponibilidad || overflowavAilability,
			reagendarEdit?.start,
			reagendarEdit?.end,
		)
	}

	console.log(overflowavAilability)

	return (
		<Form>
			<MainTitle value='Resumen de agendamiento'
				onClick={closeResumeForm}
			/>
			<SubtitleBlue value='Detalle orden de servicio' />

			<div className="row__reScheduleForm space-between">
				<div className="left__reScheduleForm">
					<div className="column__reScheduleForm">
						<LabelDrawer value='Fecha del servicio' required={false} />
						<Input
							className='input_form_reagendar'
							value={reagendarEdit?.date}
							style={{ height: "40px", boxShadow: 'none' }}
							readOnly
						/>
					</div>
				</div>
				<div className="right__reScheduleForm">
					<div className="column__reScheduleForm">
						<LabelDrawer value='Tipo de orden de servicio' required={false} />
						<Input
							className='input_form_reagendar'
							style={{ height: "40px", boxShadow: 'none' }}
							value={reagendarEdit?.ostype}
							readOnly
						/>
					</div>
				</div>
			</div>

			<Br />

			<LabelDrawer value='Técnico' required={false} />
			<Select
				isMulti
				style={{ height: "40px" }}
				options={options}
				defaultValue={options}
				readOnly
				placeholder=''
				menuIsOpen={false}
				styles={customStyles}
				isDisabled
			/>

			<Br />

			<BlueResumeUI height={136}>
				<div className="row__reScheduleForm">
					<b className='title'>Resumen de solicitud</b>
				</div>

				<div className="row__reScheduleForm">
					<b className='blue_breadcum'>Técnico:</b>
					<p className='p'>{reagendarEdit?.technician}</p>
				</div>

				<div className="row__reScheduleForm">
					<b className='blue_breadcum'>Fecha:</b>
					<p className='p'>{formatDay(reagendarEdit?.date)} </p>
				</div>

				<div className="row__reScheduleForm">
					<b className='blue_breadcum'>Hora:</b>
					<p className='p'>
						{reagendarEdit?.start}
						{amOrPm(reagendarEdit?.start) === true ? ' p.m' : ' a.m'} - {" "}

						{reagendarEdit?.end}
						{amOrPm(reagendarEdit?.end) === true ? ' p.m' : ' a.m'}</p>
				</div>
			</BlueResumeUI>

			<Br />

			<div className="bottom_section__reScheduleForm">
				<BottomSection onCancel={closeResumeForm} onAcept={patchEvent} />
			</div>

		</Form>
	)
}

export default ReScheduleForm