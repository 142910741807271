/* eslint-disable */
import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import styled from "styled-components";

// hooks & helpers
import { selectTab } from "../../store/tab/tabSlice";
import { permissionCheck } from "../../helpers/helpers";

// custom
import { Panel, PanelBody, PanelHeader } from "../../components/panel/panel";
import { TabsPanel } from "../../components/tabs/Tabs";
import AvailabilityPanel from "./availability/AvailabilityPanel";
import GeneralPanel from "./general";
import Documentation from "./documentation";
import LunchPanel from "./lunch";
import HistoricalPanel from "./historic";
import TypesServicesOrdensPanel from "./category";
import { devices, largeSizes, mediumSizes, smallSizes } from "../../helpers/sizes";

export default function TechniciansDetails() {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	// const selectedTab = useSelector((state) => state?.tabs?.selectedTab);
	const reduxState = useSelector((state) => state);

	// Obtenemos el estado de la pestaña seleccionada desde Redux
	const selectedTab = reduxState?.tabs?.selectedTab;
	// const handleTabChange = (index) => dispatch(selectTab(index))
	const handleTabChange = (index) => dispatch(
		selectTab({
			selectedTab: index,
			showTabs: true,
		})
	)

	// const array = useSelector((state) => state?.authState?.permissions)
	const array = reduxState?.authState?.permissions;
	const rolPermission = (value) => {
		return permissionCheck(array, value)
	}

	let tabs = [
		{ name: "General", component: <GeneralPanel data={location?.state?.params} /> },
		{ name: "Tipos de OS", component: <TypesServicesOrdensPanel data={location?.state?.params} /> },
		{ name: "Almuerzo", component: <LunchPanel data={location?.state?.params} /> },
		{ name: "Disponibilidad", component: <AvailabilityPanel data={location?.state?.params} /> },
		{ name: "Históricos", component: <HistoricalPanel data={location?.state?.params} /> }
	];


	const documentationTab = { name: "Documentación", component: <Documentation data={location?.state?.params} /> };

	if (rolPermission("view_technicianpic") === true) {
		tabs.splice(4, 0, documentationTab);
	}

	const technicianName = location?.state?.params?.full_name
	const fechaOriginal = location?.state?.params?.technician?.created;
	const created = dayjs(fechaOriginal, 'DD-MM-YYYY HH:mm:ss').locale('es').format('DD MMMM YYYY');

	const createdStyles = { color: "#001737", fontWeight: "bold", fontSize: "12px", textAlign: "right" }

	const tooltipText = (
		<ContainerTooltip>
			<span className="tittle">Información de ayuda</span>
			<span className="description">Ficha con datos generales asociados al técnico.</span>
		</ContainerTooltip>
	)

	return (
		<Panel>
			<ContainerHeader>
				<PanelHeader noButton={true} typeButton={"edit"}>

					<span className="container_header">
						<span className="sub_container_header">

							{
								technicianName && <span className="title_technicias" >Técnico {technicianName} </span>
							}
							<Tooltip
								title={tooltipText}
								color='white'
								arrow={false}
								placement="bottom"
								overlayInnerStyle={{
									height: "95px",
									maxHeight: '100px'
								}}
							>
								<i className="fa-solid fa-circle-info circle-tooltip"></i>
							</Tooltip>

							<span className="container_subtitle_technicilas">
								<span style={{ display: "flex", alignContent: "center" }}>
									<span className="font_12_size">Disponibilidad |</span>
								</span>

								<span style={{ cursor: "pointer", fontWeight: "bold", display: "flex", alignContent: "center" }}
									onClick={() => history.push("/technicians")}>
									<span className="font_12_size" style={{ paddingLeft: 3 }}> Listado de técnicos |</span>
								</span>

								<span style={{ display: "flex", alignContent: "center" }}>
									<span className="font_12_size" style={{ paddingLeft: 3 }}>Detalle</span>
								</span>
							</span>
						</span>

						{
							created && <span className="date_container">Fecha de creación: <span>{created}</span> </span>
						}

					</span>


				</PanelHeader>

			</ContainerHeader>

			<TabsPanel selectedTabClassName="is-selected">
				{/* <PanelBody> */}
				<span className="TabsList">
					{tabs?.map((tab, index) => (
						<span key={index} className={`Tab font-size-tab ${selectedTab === index ? "is-selected" : ""}`} onClick={() => handleTabChange(index)}>{tab.name}</span>
					))}
				</span>
				{tabs[selectedTab]?.component}
				{/* </PanelBody> */}

			</TabsPanel>
		</Panel>
	);
}

const ContainerIcon = styled.div`
	.icon {
		background-color: #adadad;
		padding: 5px;
		border-radius: 50px;
		margin-right: 10px;

		:hover {
			color: #333;
		}
	}
`;
const ContainerTooltip = styled.div`
	display: flex;
	flex-direction: column;

	.tittle{
		color: #868E96;
		font-size: 14px;
		margin-bottom: 15px;
	}

	.description{
		color: #172B4D;
		font-size: 12px;
		width: 150px;
	}
`;

const ContainerHeader = styled.div`
.container_header{
	width: 100%;
	display: flex;
	align-content: center;
	justify-content: space-between;
	align-items: center;
}

.container_header .sub_container_header{
	display: flex;
	align-content: center;
}

.sub_container_header .title_technicias{
	color: #001737;
	font-weight: bold;
	font-size: ${smallSizes.fontSize.fontSize24};
}

.sub_container_header .circle-tooltip{
	margin: 10px 10px 0 5px;
	color: #00388B;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.sub_container_header .container_subtitle_technicilas{
	color: #001737;
	font-weight: 400;
	display: flex;
	align-content: center;
	height: 13px;
	margin-top: 8px;
	fontSize: ${smallSizes.fontSize.fontSize12};
}

.date_container{
	color: #001737;
	font-weight: bold;
	text-align: right;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.font_12_size{
	font-size: ${smallSizes.fontSize.fontSize12};
}

//-------------------------------------------------------------

@media screen and (min-width: ${devices.smallDesk}){
	.sub_container_header .title_technicias{;
		font-size: ${smallSizes.fontSize.fontSize24};
	}

	.sub_container_header .circle-tooltip{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.sub_container_header .container_subtitle_technicilas{
		fontSize: ${smallSizes.fontSize.fontSize12};
	}

	.date_container{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.font_12_size{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.mediumDesk}){
	.sub_container_header .title_technicias{;
		font-size: ${mediumSizes.fontSize.fontSize24};
	}

	.sub_container_header .circle-tooltip{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.sub_container_header .container_subtitle_technicilas{
		fontSize: ${mediumSizes.fontSize.fontSize12};
	}

	.date_container{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.font_12_size{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
}

@media screen and (min-width: ${devices.largeDesk}){
	.sub_container_header .title_technicias{;
		font-size: ${largeSizes.fontSize.fontSize24};
	}

	.sub_container_header .circle-tooltip{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.sub_container_header .container_subtitle_technicilas{
		fontSize: ${largeSizes.fontSize.fontSize12};
	}

	.date_container{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.font_12_size{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
}
`;
