/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Select, DatePicker, TimePicker, notification, Input } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import moment from 'moment';

// api
import { getTechnician } from '../../../api/calendar';
import { getAbsenceChoices, onAbsenceCreate } from '../../../api/holiday';
import { myFetchGet } from '../../../services/services';

// hooks & helpers
import UseOperator from '../../../hooks/UseOperator';
import { disabledHours, format, hourFormat, validarCampos } from '../../../helpers/helpers'

// custom
import BlueResumeUI from '../../../components/BlueResumeUI/BlueResumeUI';
import CustomModal from '../../../components/modal/CustomModal';
import SubtitleBlue from '../../../components/text/drawer/subtitleBlue';
import LabelDrawer from '../../../components/text/drawer/label';
import MainTitle from '../../../components/text/drawer/mainTitle';
import BottomSection from '../../../components/form/bottomSection';
import Form from '../../../components/form/index.jsx'
import Br from '../../../components/br/Br';
import './index.scss'

dayjs.locale('es');

export default function NewAbsenceForm({ setIsVisibleForm }) {
	const onCloseModal = () => setIsVisibleForm(false)
	const { storedOperatorID } = UseOperator()
	const currentMonth = moment().format('MM');
	const currentYear = moment().format('YYYY');

	const [technicians, setTechnicians] = useState([])
	const [technicianID, setTechnicianID] = useState(null)
	const [technicianName, setTechnicianName] = useState(null)
	const [technicianStatus, setTechnicianStatus] = useState(null)

	const [startTime, setStartTime] = useState(null)
	const [endTime, setEndTime] = useState(null)

	const [startHour, setStartHour] = useState(null)
	const [endHour, setEndHour] = useState(null)

	const [absenceType, setAbsenceType] = useState(null)
	const [selectedTypeID, setSelectedTypeID] = useState(null)
	const [nameType, setNameType] = useState(null)
	const [visibleComent, setVisibleComent] = useState(false)
	const [coment, setComent] = useState(null)

	const { Option } = Select;
	const { TextArea } = Input;

	useEffect(() => {
		storedOperatorID && getTechnician(storedOperatorID).then(res => setTechnicians(res))
		storedOperatorID && getAbsenceChoices().then(res => setAbsenceType(res?.type))
	}, [storedOperatorID])

	const verifyTechnicianStatus = async (currentMonth, storedOperatorID, technicianID) => {
		setTechnicianStatus(null)

		try {
			const res = await myFetchGet(`api/v1/calendar_technician/?operator=${storedOperatorID}&month=${currentMonth}&year=${currentYear}&technician=${technicianID}`);
			setTechnicianStatus(200)
			return {
				message: "El técnico posee horas disponibles",
				status: 200,
			}


		} catch (e) {

			const statusCode = e?.response?.status;
			if (statusCode === 400) {
				//400 refiere a que el tecnico no tiene disponibilidad
				setTechnicianID(null)
				setTechnicianStatus(statusCode)
			}

			return {
				message: "El técnico no posee horas disponibles",
				status: statusCode,
			}
		}
	}

	useEffect(() => {
		technicianID && verifyTechnicianStatus(currentMonth, storedOperatorID, technicianID)
	}, [technicianID])

	const onTypeChange = (value) => {
		// Se compara si value es igual 4 se pueda mostrar el comentario
		if (value === 4) {
			setVisibleComent(true)
		} else {
			setVisibleComent(false)
		}

		setSelectedTypeID(value)
		const item = absenceType?.filter(x => x.ID === value)
		setNameType(item[0]?.name)
	}

	const onTechnicianChange = (value) => {
		setTechnicianID(value)
		const item = technicians?.filter(x => x.ID === value)
		setTechnicianName(item[0]?.name + " " + item[0]?.last_name)
	}

	const onStartDateChange = (value) => setStartTime(value)
	const onEndDateChange = (value) => setEndTime(value)

	const onStartHourChange = (_, timeString) => setStartHour(timeString)
	const onEndHourChange = (_, timeString) => setEndHour(timeString)

	const [inputStatus, setInputStatus] = useState({
		input1: '',
		input2: '',
		input3: '',
		input4: '',
		input5: '',
		input6: '',
		input7: '',
		input8: '',
	});

	let absenceData = {
		"tecnico": technicianID,
		"status": 0,
		"operator": storedOperatorID,
		"type": selectedTypeID,
		"comentarios": coment,
		"time_start": startTime,
		"time_end": endTime,
		"start": startHour,
		"end": endHour,
	}

	// Validar que los campos requeridos no sean nulos, cuando no tenemos la seccion de otros
	const requiredFields = ['tecnico', 'status', 'operator', 'type', 'time_start', 'time_end', 'start', 'end'];
	const isValid = requiredFields.every(field => absenceData[field] !== null && absenceData[field] !== '');
	// cuando sea typo otros se validara que el comentario no este vacio.
	const shouldRenderComponent = absenceData.type === 4 && Object.values(absenceData).every(value => value !== null && value !== '');

	console.log(`${selectedTypeID} comentario ${coment}`)
	const newAbsence = () => {
		if (technicianID === null ||
			selectedTypeID === null ||
			(selectedTypeID === 4 && coment === null) || // comparacion que si esta en typo otros y comentario vacio no me deje enviar nada
			(selectedTypeID === 4 && coment === '') ||
			startTime === null ||
			endTime === null ||
			startHour === null ||
			endHour === null
		) {
			setInputStatus({
				input1:
					technicianID === null ? 'error'
						: technicianStatus === 400 ? 'error'
							: '',
				input2: selectedTypeID === null ? 'error' : '',
				input3: startTime === null ? 'error' : '',
				input4: endTime === null ? 'error' : '',
				input5: startHour === null ? 'error' : '',
				input6: endHour === null ? 'error' : '',
				input7: selectedTypeID === 4 && coment === null ? 'error' : '',// comparacion que si esta en typo otros y comentario vacio me genere error
				input8: selectedTypeID === 4 && coment === '' ? 'error' : ''
			});
			notification.warning({
				style: { fontWeight: 'bold' },
				message: 'Completa todos los campos',
				placement: 'bottomLeft',
				duration: 2,
			})

			return;
		}

		if (technicianStatus === 400 || technicianStatus === null) {

			console.log("No se puede crear")
			return
		}

		if ((isValid && absenceData.type !== 4) || shouldRenderComponent) {
			onAbsenceCreate(absenceData)
		}
	}

	// funcion de recoleccion de datos de textArea
	const onComent = ({ target }) => {
		setComent(target.value)
	}


	return (
		<CustomModal onClick={onCloseModal}>
			<Form>
				<MainTitle
					value="Registro de solicitud de ausencia"
					onClick={onCloseModal}
				/>

				<SubtitleBlue value="Datos de la solicitud" />

				<span className="row space-between" style={{ gap: "12px" }}>

					<span className="column" style={{ flex: 1 }}>
						<LabelDrawer value="Nombre del técnico" />
						<Select
							placeholder="Seleccionar"
							style={{ width: "100%" }}
							onChange={onTechnicianChange}
							status={inputStatus?.input1}
						>
							{
								technicians?.map((item, i) => (
									<Option key={item?.ID} value={item?.ID}>{item?.name} {item?.last_name}</Option>
								))
							}
						</Select>
					</span>

					<span className="column" style={{ flex: 1 }}>
						<LabelDrawer value="Tipo de ausencia" />
						<Select
							style={{ width: "100%" }}
							onChange={onTypeChange}
							placeholder="Seleccionar"
							status={inputStatus?.input2}
						>
							{absenceType?.map((item) => (
								<Option key={item?.ID} value={item?.ID}>
									{item?.name}
								</Option>
							))}
						</Select>
					</span>

				</span>

				{technicianStatus === 400 ? (
					<span style={{ color: "red", marginTop: 5 }}>El técnico seleccionado no tiene horario disponible</span>
				) : null}

				<Br />

				{visibleComent &&
					<div>
						<LabelDrawer value="Comentario" />
						<TextArea
							className='input_font_form_absences'
							rows={2}
							onChange={onComent}
							status={inputStatus?.input7 || inputStatus?.input8}
						/>
						<Br />
					</div>}

				<SubtitleBlue value="Inicio" />
				<div className='row space-between' style={{ height: 60, gap: "12px" }}>
					<span className="column" style={{ flex: 1 }}>
						<LabelDrawer value="Fecha" />
						<DatePicker
							inputReadOnly
							style={{ height: "40px" }}
							format={format}
							onChange={(_, dateString) => onStartDateChange(dateString)}
							status={inputStatus?.input3}
							clearIcon={false}
						/>
					</span>

					<span className="column" style={{ flex: 1 }}>
						<LabelDrawer value="Hora" />
						<TimePicker
							style={{ height: "40px" }}
							inputReadOnly
							format={hourFormat}
							disabledHours={disabledHours}
							onChange={onStartHourChange}
							defaultOpenValue={dayjs('07:00', 'HH:mm')}
							status={inputStatus?.input5}
							clearIcon={false}
						/>
					</span>
				</div>

				<Br />

				<SubtitleBlue value="Fin" />
				<div className='row space-between' style={{ height: 60, gap: "12px" }}>
					<span className="column" style={{ flex: 1 }}>
						<LabelDrawer value="Fecha" />
						<DatePicker
							inputReadOnly
							style={{ height: "40px" }}
							format={format}
							onChange={(_, dateString) => onEndDateChange(dateString)}
							status={inputStatus?.input4}
							clearIcon={false}
						/>
					</span>

					<span className="column" style={{ flex: 1 }}>
						<LabelDrawer value="Hora" />
						<TimePicker
							style={{ height: "40px" }}
							inputReadOnly
							format={hourFormat}
							disabledHours={disabledHours}
							onChange={onEndHourChange}
							defaultOpenValue={dayjs('07:00', 'HH:mm')}
							status={inputStatus?.input6}
							clearIcon={false}
						/>
					</span>
				</div>
				<Br />

				{(isValid || shouldRenderComponent)
					&& (
						<BlueResumeUI colorBackground='#F7FAFC' colorBorder='#DDE4EB'>
							<span className='title__row_breadcum__absenceForm'>Resumen de solicitud</span>
							<div className="row_breadcum">
								<p className='subtitle_bold'>Técnico:</p>
								<p className='single_text'>{technicianName} </p>
							</div>

							<div className="row_breadcum">
								<p className='subtitle_bold'>Permiso de ausencia:</p>
								<p className='single_text'>Desde: {startTime} {startHour} </p>
								<p className='single_text' style={{ marginLeft: 7 }}> Hasta {endTime} {endHour} </p>
							</div>

							<div className="row_breadcum">
								<p className='subtitle_bold'>Tipo de ausencia:</p>
								<p className='single_text'> {nameType} </p>
							</div>
							{visibleComent &&
								<>
									<p className='subtitle_coment'>Comentario</p>
									<p className='single_coment'> {coment} </p>
								</>
							}


						</BlueResumeUI>
					)}
				<Br />
				<BottomSection onCancel={onCloseModal} onAcept={newAbsence} />
			</Form>
		</CustomModal >
	)
}