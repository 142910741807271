import React, { useEffect, useState } from 'react';
import { DatePicker, Select, Switch, Timeline, notification } from 'antd';
import Form from '../../../../components/form/index.jsx';
import SubtitleBlue from '../../../../components/text/drawer/subtitleBlue/index.jsx';
import LabelDrawer from '../../../../components/text/drawer/label/index.jsx';
import Br from '../../../../components/br/Br.jsx';
import { format } from '../../../../helpers/helpers.js';
import { myFetch } from '../../../../services/services.js';
import { getDirections, getListZones } from '../../../../api/available_hours.js';
import './index.scss';

import ReactSelect from 'react-select';
import MainTitle from '../../../../components/text/drawer/mainTitle/index.jsx';
import BottomSection from '../../../../components/form/bottomSection/index.jsx';

import { debounce } from "lodash";
import dayjs from 'dayjs';
import styled from 'styled-components';
import UseOperator from '../../../../hooks/UseOperator.jsx';

import style from './zoneAvilitationForm.module.css'

const optionsPeriod = [];
for (let i = 2; i < 20; i++) {
	const value = i;
	optionsPeriod.push({
		label: value,
		value
	})
}

const ZoneAvilitationForm = ({
	onModalZoneClose,
	technicianID,
	sendToForm,
	disponibilityID,
	data,
	dateEnd,
	avalityOraculoOperator,
	repetition
}) => {
	console.log(repetition
	)
	const [culmination, setCulmination] = useState(false);
	const [endDate, setEndDate] = useState(null);

	// resultado de la api getDirections
	const [searchResults, setSearchResults] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState(null);
	const [searchValue, setSearchValue] = useState("");
	const [showOptions, setShowOptions] = useState(false)
	const [inputStatus, setInputStatus] = useState({ input1: '', input2: '' })

	const [dateRequired, setDateRequired] = useState(false);

	// Estado para el timeLine
	const [itemData, setItemData] = useState([]);
	const [visibleEventsSpecial, setVisibleEventsSpecial] = useState(false);

	const [selectdPeriod, setSelectdPeriod] = useState(disponibilityID ? repetition : 2);


	const handleInputChange = (newValue) => setSearchValue(newValue);
	const handleChange = (selected) => {
		setSelectedOptions(selected);
	}

	const regions = selectedOptions?.map((option) => option?.value);

	const { storedOperatorID } = UseOperator()

	const options = searchResults?.map(item => {
		return {
			value: item?.id,
			label: item?.full_direction
		}
	})

	const customStyles = {
		multiValue: (base) => ({
			...base,
			backgroundColor: "white",
			color: '#5A607F',
			border: '1px solid #5A607F',
			borderRadius: "2px",
			fontSize: '12px'
		}),
		multiValueLabel: (base) => ({ ...base, color: '#5A607F' }),
		multiValueRemove: (base, state) => ({
			...base,
			backgroundColor: 'white',
			color: '#5A607F',
			':hover': { backgroundColor: "white" },
		}),
		control: (base, state) => ({
			...base,
			borderColor: inputStatus.input1 === "error" ? "red" : '#4096ff', // Cambia el color del borde en foco
			boxShadow: state.isFocused ? 'none' : base.boxShadow, // Cambia el color del sombreado en foco
		}),
	};

	const habilitationZoneCreate = async () => {
		const updatedDisponibility = sendToForm?.map(obj => ({ ...obj, dir: regions }));
		let habilitationData = {
			technician: technicianID,
			disponibility: updatedDisponibility
		};

		if (disponibilityID) habilitationData['ID'] = disponibilityID

		if (culmination) habilitationData['period'] = selectdPeriod



		if (selectedOptions === null && dateRequired) {
			setInputStatus({
				input1: selectedOptions === null ? 'error' : '',
				input2: endDate === null && (dateEnd === "" || dateEnd === undefined) && dateRequired ? 'error' : '',
			});

			notification.warning({
				style: { fontWeight: 'bold' },
				message: "Completa todos los campos",
				placement: 'bottomLeft',
				duration: 2.5,
			})
			return
		}

		if (culmination === false) {
			if (selectedOptions === null) {
				setInputStatus({
					input1: selectedOptions === null ? 'error' : '',
					input2: '',
				});

				notification.warning({
					style: { fontWeight: 'bold' },
					message: "Completa todos los campos",
					placement: 'bottomLeft',
					duration: 2.5,
				})
				return
			}
		}

		try {
			if (avalityOraculoOperator === true) {
				const response = await myFetch('api/v1/create_disponibility/', habilitationData);
				createZone(response.data)
			} else {
				await myFetch('api/v1/create_disponibility/', habilitationData);
				if (disponibilityID) {
					notification.success({
						style: { fontWeight: 'bold' },
						message: 'Se ha actualizado la nueva habilitación',
						placement: 'bottomLeft',
						duration: 1.5,
						onClose: () => window.location.reload()
					});
				} else {
					notification.success({
						style: { fontWeight: 'bold' },
						message: 'Se ha creado la nueva habilitación',
						placement: 'bottomLeft',
						duration: 1.5,
						onClose: () => window.location.reload()
					});
				}

			}

		} catch (e) {
			notification.error({
				style: { fontWeight: 'bold' },
				message: 'No se ha creado la nueva habilitación',
				placement: 'bottomLeft',
				duration: 1.5,
				onClose: () => console.log('error', e)
			});
		}
	};

	const createZone = async (data) => {

		const zoneList = selectedOptions.map(item => {
			return {
				name: item.label,
				ID: item.value
			}
		})

		const body = {
			disponibility_list: data,
			zones_list: zoneList,
			technician: technicianID
		}

		try {
			if (disponibilityID) {
				await myFetch('api/v1/zones/0/', body, 'PATCH')
				notification.success({
					style: { fontWeight: 'bold' },
					message: 'Se ha actulizado la nueva habilitación',
					placement: 'bottomLeft',
					duration: 1.5,
					onClose: () => window.location.reload()
				});
			} else {
				await myFetch('api/v1/zones/', body)
				notification.success({
					style: { fontWeight: 'bold' },
					message: 'Se ha creado la nueva habilitación',
					placement: 'bottomLeft',
					duration: 1.5,
					onClose: () => window.location.reload()
				});
			}

		} catch (error) {
			console.log(error)
		}

	}

	// funcion que captura la tecla cuando se deja de oprimir
	const onKeyUp = () => {
		handlerSearch()
	}

	// funcion que captura al oprimir tecla
	const onKeyDown = () => {
		handlerSearch.cancel()
	}

	//  funcion para color aleatorio sin tener encuenta el blanco 
	const getRandomHex = () => {
		let hex;

		do {
			hex = Math.floor(Math.random() * 16777215).toString(16);
		} while (hex === "ffffff")

		return '#' + hex;
	}

	// Funcion para peticion de direcciones
	const handlerSearch = debounce(() => {
		avalityOraculoOperator === false && getDirections(searchValue)
			.then(res => {
				setSearchResults(res)
				setShowOptions(true)
			});

	}, 750);

	const handleChangeSelect = (value) => {
		setSelectdPeriod(value)
	};

	useEffect(() => {
		if (searchValue?.length === 0) {
			if (avalityOraculoOperator === false) {
				setSearchResults([])
				setShowOptions(false)
			} else {
				setShowOptions(false)
			}

		} else {

			setShowOptions(true)
		}
	}, [searchValue])

	useEffect(() => {
		if (data?.zones.length > 0) {
			setSelectedOptions(data?.zones)
		} else {
			setSelectedOptions(null)
		}
	}, []);

	useEffect(() => {
		const verify = !!sendToForm.find(item => item.type === 1);
		setDateRequired(verify);
	}, [sendToForm]);

	// Se realiza el filtrado para visualizar los eventos especiales.
	useEffect(() => {
		const dataSpecial = sendToForm.filter(item => item.type === 1);
		if (dataSpecial.length > 0) {
			const data = dataSpecial.map(item => {
				const color = getRandomHex();
				const day = dayjs(item.date, 'DD MM YYYY').format('dddd');
				return {
					dot: <span style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 30, height: 30, borderRadius: "100%", background: color }}> <i className='fa-solid fa-calendar-days' /> </span>,
					color: 'white',
					children:
						<section style={{ display: 'flex', flexDirection: "column" }}>
							<span>{`${day} ${dayjs(item.date, 'DD MM YYYY').format('DD MMMM YYYY')}`}</span>
							<span>{`Bloque de horario ${dayjs(item.start, 'HH:mm').format('h:mm a')} - ${dayjs(item.end, 'HH:mm').format('h:mm a')}`}</span>
						</section>
				}
			});
			setItemData(data);
			setVisibleEventsSpecial(true);
		} else {
			setVisibleEventsSpecial(false);
		}
	}, [])

	useEffect(() => {
		if (avalityOraculoOperator === true && storedOperatorID) {
			getListZones(storedOperatorID).then(res => {
				const coordinatesList = res.map(item => {
					return {
						id: item.ID,
						full_direction: item.name
					}
				})
				setSearchResults(coordinatesList)
			})
		}

	}, [storedOperatorID])

	return (
		<Form>
			<MainTitle
				value="Habilitación de zona"
				onClick={onModalZoneClose}
			/>

			<SubtitleBlue value="Zona de cobertura" />
			<LabelDrawer value="Región o comuna" />
			<div onKeyUp={onKeyUp}>
				<ReactSelect
					options={options}
					isMulti
					isSearchable
					value={selectedOptions}
					onInputChange={handleInputChange}
					menuIsOpen={showOptions === false ? false : true}
					onChange={handleChange}
					styles={customStyles}
					placeholder="Busca una ciudad"
					className='select_font_12'
					onKeyDown={onKeyDown}
				/>
			</div>



			<div className="switch_container">
				<SubtitleBlue value="Vigencia de disponibilidad" />
				<div className="row">
					<span className='text_switch select_font_12'>
						Repetir configuración de periodo?
					</span>
					<span className="switch_box">
						<span className='select_font_12'>No</span>
						<Switch
							style={{ margin: '0 5px' }}
							onChange={() => setCulmination(!culmination)}
							defaultChecked={culmination}
						/>
						<span className='select_font_12'>Si</span>
					</span>
				</div>
			</div>

			<Br />

			{culmination && (
				<section>
					<span className={style.title_period}>Cantidad de periodos</span>

					<div className={style.container_select}>
						<ContainerSelect>
							<Select
								defaultValue={selectdPeriod}
								style={{ width: 84 }}
								options={optionsPeriod}
								onChange={handleChangeSelect}
							/>
						</ContainerSelect>
					</div>
				</section>
			)}

			<Br />

			{visibleEventsSpecial && (

				<section>
					<span>
						<SubtitleBlue value="Eventos especiales agregados" />
					</span>
					<Container>
						<Timeline items={itemData} />
					</Container>
				</section>
			)
			}



			<BottomSection onCancel={onModalZoneClose} onAcept={habilitationZoneCreate} />
		</Form>
	);
};

export default ZoneAvilitationForm;

const Container = styled.div`

margin-top: 12px;
margin-left: 12px;
max-height: 330px; 
overflow-y: auto;
// ----------------------------------
&::-webkit-scrollbar {
	width: 10px;
	height: 6px;
}

&::-webkit-scrollbar-track {
	border-radius: 10px;
	background: rgba(0, 0, 0, 0.1);
}

&::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: rgba(0, 0, 0, 0.2);
}

&::-webkit-scrollbar-thumb:hover {
	background: rgba(0, 0, 0, 0.4);
}

&::-webkit-scrollbar-thumb:active {
	background: rgba(0, 0, 0, 0.9);
}

.ant-timeline .ant-timeline-item-head-custom{
	inset-block-start: 14px;
}

.ant-timeline-item-content{
	margin-inline-start: 35px;
}

.ant-timeline{
	padding-left: 11px;
	padding-top: 5px;
}
`

const ContainerSelect = styled.div`
.ant-select .ant-select-arrow{
	margin-top: 0;
}

.ant-select .ant-select-selection-item{
	font-size: 12px;
}

@media screen and (min-width: 1280px) {
	.ant-select .ant-select-selection-item{
		font-size: 12px;
	}
}

@media screen and (min-width: 1366px) {
	.ant-select .ant-select-selection-item{
		font-size: 13.5px;
	}
}

@media screen and (min-width: 1920px) {
	.ant-select .ant-select-selection-item{
		font-size: 15.18px;
	}
}
`