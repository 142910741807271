import { useEffect, useRef } from "react";
import L from "leaflet";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-routing-machine";
import { useMap } from "react-leaflet";

export default function Routing({ coordinates }) {
	const map = useMap();
	const controlRef = useRef(null);

	useEffect(() => {
		if (coordinates) {

			const waypoints = coordinates.map(item => L.latLng(item.lat, item.lng))
			console.log(waypoints)
			controlRef.current = L.Routing.control({
				waypoints,
				draggableWaypoints: false,
				addWaypoints: false,
				lineOptions: {
					styles: [
						{ color: '#A5D2EF', opacity: 0.15, weight: 9 },
						{ color: '#A5D2EF', opacity: 0.8, weight: 6 },
						{ color: '#A5D2EF', opacity: 1, weight: 2 }
					]
				},
				createMarker: function (i, waypoint, n) {
					return L.circleMarker(waypoint.latLng, {
						radius: 10, // El radio del círculo
						fillColor: "#ff7800", // El color de relleno del círculo
						color: "#000", // El color del borde del círculo
						weight: 1, // El ancho del borde del círculo
						opacity: 1, // La opacidad del borde del círculo
						fillOpacity: 0.8 // La opacidad del relleno del círculo
					});
				},
				show: false,
			}).addTo(map);
		}
	}, [map, coordinates]);

	return null;
}