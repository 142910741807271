import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { myFetch, myFetchGet } from "../../../services/services";

import { Empty, Input } from 'antd';

import style from "./search.module.css"
import UseOperator from '../../../hooks/UseOperator';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { selectTab } from '../../../store/tab/tabSlice';

const Search = () => {

	const [visibleSearchResult, setVisibleSearchResult] = useState(false);
	const [dataSearch, setDataSearch] = useState(null);
	const [search, setSearch] = useState(null);
	const [empityData, setEmpityData] = useState(false);

	const { Search } = Input;
	const { storedOperatorID } = UseOperator();
	const dispatch = useDispatch();
	const history = useHistory();

	const searchData = async (search) => {
		try {
			const response = await myFetchGet(`api/v1/general_search/?search=${search}`);
			if (Object.keys(response.results).length === 0) {

				setVisibleSearchResult(true);
				setEmpityData(true);
				return;
			}
			setDataSearch(response.results);
			setVisibleSearchResult(true);
			setEmpityData(false);
		} catch (error) {

		}
	}

	const onSearch = (value) => {
		if (value !== "") searchData(value);
		else setVisibleSearchResult(false)

	};
	const searchChangeHandler = (e) => {
		if (e.target.value !== "") setSearch(e.target.value);
		else setVisibleSearchResult(false)
	};

	const orderClickHandler = (values) => {
		const parameters = {
			id_unico: values.id_unico,
			ID: values.ID,
			fecha: values.fecha,
			service_number: values.user.service_number,
			user: values.user.user_full,
			user_full: {
				first_name: values.user.first_name,
				last_name: values.user.last_name,
				document_type: values.user.document_type.pk,
				document_type_name: values.user.document_type.name,
				document_number: values.user.document_number,
				email: values.user.email,
				phone: values.user.phone,
				direction: values.direction.name,
				direction_complementary: values.user.direction_text,
				prefijo: values.user.prefijo,
				iso: values.user.iso,
			},
			document_number: values.user.document_number,
			ostype: {
				color: values.ostype.color,
				name: values.ostype.name
			},
			technician: `${values.technician.name} ${values.technician.last_name}`,
			technician_id: values.technician.pk,
			status: values.status_name,
			status_id: values.status,
			category_questions: values.category.questions,
			category_name: values.category.name,
			auxiliaries: values.auxiliaries,
			category_id: values.category.pk,
			category_duration: values.category.duration,
			direction_complementary: values.user.direction_text,
			has_oraculo: values.user.is_brujula
		}
		console.log(parameters)
		// cundo llegue al detalle, comienza en el primer Tab
		dispatch(selectTab({ selectedTab: 0 }))

		// funcion de react-router-dom para direccionar a vista general de Os
		history.push("/serviceOrdersDetails", { params: parameters })
	}

	useEffect(() => {
		let timer;
		if (search) {
			timer = setTimeout(() => {
				searchData(search);
			}, 1000);
		}

		return () => {
			clearTimeout(timer);
		};
	}, [search])


	return (
		<ContainerSearch>
			<Search
				allowClear
				onSearch={onSearch}
				onChange={searchChangeHandler}
			/>
			{visibleSearchResult && (
				<section className={`${style.position_container_search} animate__animated animate__fadeIn`}>
					<section className={`${style.contaner_search} custom_scroll`}>
						{empityData ? <Empty
							description={
								"No se encontraron datos, por favor intente de nuevo"
							} /> : (
							<>
								<span className={style.text_blue}>Ordenes de servicio</span>
								{Object.entries(dataSearch).map(([key, value]) => (

									<section key={key} className={style.container_category}>
										<span className={style.category}>
											<span className={style.circle} style={{ background: value[0].ostype.color }} />
											<p>{key}</p>
										</span>
										{
											value.map((i, index) => (

												<section key={index} onClick={() => orderClickHandler(i)} className={style.container_select}>
													<span className={style.description_search}>
														<span className={style.text_first}>
															<b className={style.text_font}>{i.ID}</b>
															<p className={`${style.text_color} ${style.text_font}`}>{`${i.user.first_name} ${i.user.last_name}`}</p>
														</span>
														<span className={style.document_container}>
															<p className={`${style.text_color} ${style.text_font}`}>{i.user.document_type?.name}</p>
															<p className={`${style.text_color} ${style.text_font}`}>{i.user.document_number}</p>
														</span>
														<b className={`${style.text_color} ${style.text_font}`}>{i.status_name}</b>
													</span>
													<p className={`${style.text_color} ${style.text_font}`}>{i.direction.name}</p>

												</section>


											))
										}
									</section>
								))}
							</>
						)
						}
					</section>
				</section>
			)}
		</ContainerSearch>
	)
}

export default Search

const ContainerSearch = styled.div`
.ant-input-group .ant-input{
	height: 29px;
}

.ant-input-affix-wrapper:not(:last-child) {
	border-radius: 5px 0 0 5px !important;
	border: 1px solid #e0e0e0;
	height: 38px;
}

.ant-input-search
		> .ant-input-group
		> .ant-input-group-addon:last-child
		.ant-input-search-button {
		border-radius: 0 5px 5px 0 !important;
		border: 1px solid #e0e0e0;
		height: 38px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	@media screen and (min-width: 1280px) {
		height: 65px;
	}

	@media screen and (min-width: 1366px) {
		height: 73.12px;
	}

	@media screen and (min-width: 1920px) {
		height: 82.26px;
	}
`;