import { myFetch, myFetchGet, myFetchMedia } from "../services/services"
import { notification } from 'antd';

const getGalleryImage = async (IDdeOS) => {
	try {
		const res = await myFetchGet(`api/v1/ospic/${IDdeOS}/get_pics/`)
		return res

	} catch (e) {
		return e?.response?.status
	}
}

const onUploadOspic = async (closeForm, data) => {
	try {
		await myFetchMedia("api/v1/ospic/", data)
		notification.success({
			style: { fontWeight: 'bold' },
			message: 'Subido con exito',
			placement: 'bottomLeft',
			duration: 2.5,
			onClose: () => closeForm()
		})
	} catch (error) {
		notification.error({
			style: { fontWeight: 'bold' },
			message: 'Error',
			description: 'No se ha podido subir',
			placement: 'bottomLeft',
			duration: 2.5,
			onClose: () => closeForm()
		});
	}
}

const onDeleteImage = async (ID) => {
	try {
		await myFetch(`api/v1/ospic/${ID}/`, "", "DELETE")
		notification.success({
			style: { fontWeight: 'bold' },
			message: 'Eliminado con exito',
			placement: 'bottomLeft',
			duration: 3,
			onClose: () => window.location.reload()
		})
	} catch (error) {
		console.log(error);
		notification.error({
			style: { fontWeight: 'bold' },
			message: 'Error',
			description: 'No se ha podido eliminar',
			placement: 'bottomLeft',
			duration: 2.5,
			onClose: () => window.location.reload()
		})
	}
}

const getCatgeory = async (categoryId) => {
	try {
		const res = await myFetchGet(`api/v1/category/${categoryId}/`)
		return res
	} catch (error) {
		console.log(error);
	}
}


export { onDeleteImage, getGalleryImage, onUploadOspic, getCatgeory }