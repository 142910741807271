/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { DatePicker, Input, Select, Switch, notification, Tooltip, Checkbox } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { validate } from 'rut.js';
import styled from 'styled-components';

// api
import { getID_type, getTechnicianStatus, onTechnicianPost } from '../../../api/technician';
import UseOperator from '../../../hooks/UseOperator';
import { getTechnician } from '../../../api/calendar';
import LabelDrawer from '../../../components/text/drawer/label/index.jsx';
import SubtitleBlue from '../../../components/text/drawer/subtitleBlue';
import MainTitle from '../../../components/text/drawer/mainTitle/index.jsx';

import {
	disabledAfterDate,
	format,
	onlyNumber,
	onlyTextAndSpace,
} from '../../../helpers/helpers.js'

// customs
import BottomSection from '../../../components/form/bottomSection/index.jsx';
import CustomModal from '../../../components/modal/CustomModal';
import Form from '../../../components/form/index.jsx';
import Br from '../../../components/br/Br';
import './index.scss'
import { set } from 'date-fns';

const NewTechnicianForm = ({ setIsVisibleForm }) => {

	const enumerableRole = [
		{
			value: 1,
			label: "Principal"
		},
		{
			value: 2,
			label: "Auxiliar"
		},
		{
			value: 3,
			label: "Principal/Aux"
		},]

	const [userName, setUserName] = useState('')
	const [userNameError, setUserNameError] = useState(null)

	const [last_name, setLast_name] = useState('')
	const [last_nameError, setLast_nameError] = useState(null)

	const [idTypes, setIdTypes] = useState([])
	const [idNumber, setIdNumber] = useState('');
	const [idNumberError, setIdNumberError] = useState(null);

	const [birthday, setBirthday] = useState('')

	const [phone, setPhone] = useState('')
	const [phoneError, setPhoneError] = useState(null)

	const [status, setStatus] = useState(null)
	const [statusID, setStatusID] = useState(null)

	console.log("statusID", statusID)

	const [email, setEmail] = useState('')
	const [emailError, setEmailError] = useState(null)

	const [password, setPassword] = useState('')
	const [showPass, setShowPass] = useState(false)

	const [technicians, setTechnicians] = useState([])
	const [showRol, setShowRol] = useState(false)
	const [rol, setRol] = useState("")

	const [supervised_by, setSupervised_by] = useState([])

	const [selectionRole, setSelectionRole] = useState(null)

	const { storedOperatorID, operators } = UseOperator();
	const { Option } = Select;

	// errors:
	const [error, setError] = useState(false)

	const newStatus = useSelector((state) => state?.authState)

	const [prefixes, setPrefixes] = useState('')
	const errorRed = "#DC3545";

	const [showSchedule, setShowSchedule] = useState(false)

	useEffect(() => {
		const countryID = localStorage.getItem("countryID")

		storedOperatorID && getTechnician(storedOperatorID).then(res => {
			const technicalNotSuper = res?.filter(item => item.role !== 0)
			setTechnicians(technicalNotSuper)
		})
		countryID && getID_type(countryID).then(res => setIdTypes(res))
		storedOperatorID && getTechnicianStatus().then(res => setStatus(res))
		setRol(newStatus?.rol)

	}, [storedOperatorID])

	useEffect(() => {
		if (idTypes?.iso === "PE") {
			setPrefixes("+51")
		} else if (idTypes?.iso === "CL") {
			setPrefixes("+56")
		} else if (idTypes?.iso === "CO") {
			setPrefixes("+57")
		} else if (idTypes?.iso === "VE") {
			setPrefixes("+58")
		}
	}, [idTypes?.iso])

	// const ISO = "CL"
	const ISO = idTypes?.iso
	const isoFlag = ISO?.toLowerCase()

	const onIdentificationNumber = (e) => setIdNumber(e?.target?.value)
	const onPasswordChange = (e) => setPassword(e?.target?.value)

	// --------------------------------------------------
	// --------------------------------------------------
	const [identity_type, setIdentity_type] = useState(null)
	const operatorSelected = operators?.filter(op => op?.ID === storedOperatorID)

	const onIdentity_typeChange = (value) => setIdentity_type(value)
	// ISO desde el operador
	const operatorISO = operatorSelected[0]?.country_iso


	const regexFormats = {
		"CL-Pass": /^[A-Z]{2}[0-9]{7}$/,	// 'pasaporte debe contener 2 MAYUSCULAS + 7 numeros sin puntos

		"PE-DNI": /^[0-9]{8}$/, // DNI de peru debe llevar 8 digitos
		"PE-CE": /^[0-9]{8}$/, // CE de peru debe llevar 8 digitos
		"PE-Pass": /^[A-Z]{2}\d{7}$/, // Pasaporte de peru debe llevar 2letra MAYUSCULA + 7 numeros
		"PE-RUT": /^[0-9]{11}$/, // RUT de peru debe llevar 10 digitos

		"default": /^\d{9}$ ///default
	}

	useEffect(() => {
		switch (operatorISO) {
			case 'CL':
				//  id 6 = RUT     ID 7 = Pass
				if (identity_type === 6) {
					const validationResult = validate(idNumber, { format: false })
					validationResult === false ? setIdNumberError("Formato de RUT incorrecto") : setIdNumberError(null)
				}

				if (identity_type === 7) {
					regexFormats["CL-Pass"].test(idNumber) === false
						? setIdNumberError("Formato de Pasaporte incorrecto, ej: AA1234567")
						: setIdNumberError(null)
				}

			case 'PE':
				if (identity_type === 1) { regexFormats["PE-DNI"].test(idNumber) === false ? setIdNumberError("Formato de DNI incorrecto, ej: 12345678") : setIdNumberError(null) }
				if (identity_type === 2) { regexFormats["PE-CE"].test(idNumber) === false ? setIdNumberError("Formato de Cedula incorrecto, ej: 12345678") : setIdNumberError(null) }
				if (identity_type === 3) { regexFormats["PE-Pass"].test(idNumber) === false ? setIdNumberError("Formato de Pasaporte incorrecto, ej: AB1234567") : setIdNumberError(null) }
				if (identity_type === 4) { regexFormats["PE-RUT"].test(idNumber) === false ? setIdNumberError("Formato de RUT incorrecto, ej: 12345678901") : setIdNumberError(null) }
		}
	}, [idNumber, identity_type, operatorISO])
	// --------------------------------------------------
	// --------------------------------------------------


	const onCloseModal = () => setIsVisibleForm(false)
	const handleChange = (value) => setSupervised_by(value)
	const onBirthdayChange = (_, datestring) => setBirthday(datestring)

	const onNameChange = (e) => {
		if (onlyTextAndSpace(e.target.value)) {
			setUserNameError("")
			setUserName(e.target.value)
		} else {
			setUserName(null)
			setUserNameError("Ingresa solo texto, sin números")
		}
	}
	const onSurnameChange = (e) => {
		if (onlyTextAndSpace(e.target.value)) {
			setLast_nameError("")
			setLast_name(e.target.value)
		} else {
			setLast_name(null)
			setLast_nameError("Ingresa solo texto, sin números")
		}
	}

	const phoneOnchange = (e) => {
		if (onlyNumber(e.target.value)) {
			setPhoneError("")
			setPhone(e.target.value)

		} else {
			setPhone(null)
			setPhoneError("Ingresa solo números")
		}
	}

	const [inputStatus, setInputStatus] = useState({
		input1: '',
		input2: '',
		input3: '',
		input4: '',
		input5: '',
		input6: '',
		input7: '',
		input8: '',
		input9: '',
	});

	const onCreateTechnician = () => {

		let data = {
			"name": userName,
			"last_name": last_name,
			"id_type": identity_type,
			"id_number": idNumber,
			"phone": phone,
			"birthday": birthday,
			"status": statusID,
			"email": email,
			"password": password,
			"operator": storedOperatorID,
			"is_supervisor": showRol, // Boolean del Switch,por defecto false
			"supervised_by": supervised_by,// Array de ID de cada técnico,
			"role": selectionRole,
			"schedule_same_day": showSchedule
		}

		if (userName === '' ||
			last_name === '' ||
			identity_type === null ||
			idNumber === '' ||
			phone === '' ||
			birthday === '' ||
			password === '' ||
			email === '' ||
			(selectionRole === null && showRol === false)
		) {
			notification.warning({
				style: { fontWeight: 'bold' },
				message: 'Completa todos los campos',
				placement: 'bottomLeft',
				duration: 2,
			})

			setInputStatus({
				input1: userName === '' ? 'error' : '',
				input2: last_name === '' ? 'error' : '',
				input3: identity_type === null ? 'error' : '',
				input4: idNumber === '' ? 'error' : '',
				input5: phone === '' ? 'error' : '',
				input6: birthday === '' ? 'error' : '',
				input7: email === '' ? 'error' : '',
				input8: password === '' ? 'error' : '',
				input9: selectionRole === null && showRol === false ? 'error' : '',
			});

		} else {
			// Todos los campos están completos, continúa con la lógica de tu aplicación...
			onTechnicianPost(data)
		}
	}

	const handleChangeRole = (value) => {
		setSelectionRole(value);
	}

	return (
		<CustomModal onClick={onCloseModal}>
			<Form>
				<MainTitle value='Agregar técnico' onClick={onCloseModal} />

				<SubtitleBlue value="Datos personales" />

				<span className="row__technician__create space-between">
					<div className="left">
						<div className="column">
							<LabelDrawer value="Nombre" />
							<Input
								className='input_form_technicials'
								style={{ height: "40px" }}
								placeholder='Ingrese Nombre'
								value={userName}
								onChange={onNameChange}
								maxLength={15}
								status={inputStatus?.input1}
							/>
						</div>
						<span style={{ color: errorRed, visibility: userNameError ? "visible" : "hidden" }}>{userNameError}</span>

					</div>

					<div className="right">
						<div className="column">
							<LabelDrawer value="Apellido" />
							<Input
								className='input_form_technicials'
								style={{ height: "40px" }}
								value={last_name}
								onChange={onSurnameChange}
								maxLength={15}
								placeholder='Ingrese Apellido'
								status={inputStatus?.input2}
							/>
						</div>
						<span style={{ color: errorRed, visibility: last_nameError ? "visible" : "hidden" }}>{userNameError}</span>
					</div>

				</span>

				<Br />

				<span className="row__technician__create space-between">
					<div className="left">
						<div className="column">
							<LabelDrawer value="Documento de identidad" />
							<div className="row__technician__create space-between">
								<Select
									onChange={onIdentity_typeChange}
									style={{ width: "70px", height: "40px" }}
									placeholder="Tipo"
									status={inputStatus?.input3}
								>
									{idTypes?.data?.map(item =>
										<Select.Option key={item?.ID} value={item?.ID}>
											{item?.name}
										</Select.Option>)
									}
								</Select>

								<span className='column' style={{ width: "calc(97% - 70px)" }}>
									<Input
										id="idNumber"
										className='input_form_technicials'
										onChange={onIdentificationNumber}
										maxLength={12}
										style={{ height: 40 }}
										value={idNumber}
										placeholder='Ingrese número de documento'
										status={inputStatus?.input4}
									/>
								</span>
							</div>
						</div>



					</div>
					<div className="right">
						<div className="column">
							<LabelDrawer value="Número de teléfono" />
							<div className="row__technician__create space-between">
								<span className="phoneFlagsSelect">
									{
										isoFlag !== undefined ? (
											<img
												src={`https://flagcdn.com/20x15/${isoFlag}.png`}
												alt=""
												width="12px"
												height="12px"
											/>
										) : null
									}

									{
										prefixes && (
											<span className='prefixe_form-technicials input_form_technicials'>{prefixes}</span>
										)
									}

								</span>
								<Input
									value={phone}
									onChange={phoneOnchange}
									className="input__phoneNumber input_form_technicials"
									style={{ marginBottom: error ? 12 : 0 }}
									placeholder='Ingrese número de teléfono'
									status={inputStatus?.input5}
								/>
							</div>

						</div>
					</div>
				</span>

				<div className="row__technician__create space-between">
					<div className="left">
						<div className="column">
							<span style={{ color: errorRed, visibility: idNumberError ? "visible" : "hidden" }}>
								{idNumberError}
							</span>
						</div>
					</div>
					<div className="right">
						<div className="column">
							{
								phoneError
									? <span style={{ color: errorRed, visibility: phoneError ? "visible" : "hidden" }}>{phoneError}</span>
									: <span style={{ visibility: "visible", background: "violet" }}></span>
							}
						</div>

					</div>
				</div>

				<Br />

				<span className="row__technician__create space-between">
					<div className="left">
						<div className="column">
							<LabelDrawer value="Fecha de nacimiento" />
							<DatePicker
								style={{ height: "40px" }}
								format={format}
								placeholder="Seleccione una fecha"
								inputReadOnly
								onChange={onBirthdayChange}
								disabledDate={disabledAfterDate}
								status={inputStatus?.input6}
							/>
						</div>
					</div>
					<div className="right">
						<div className="column">
							<LabelDrawer value="Status" />
							<Select
								onChange={(value) => setStatusID(value)}
								style={{ height: "40px" }}
								placeholder={status && status[0]?.name}
							>
								{status?.map(item =>
									<Option key={item?.ID} value={item?.ID}>
										{item?.name}
									</Option>)
								}
							</Select>
						</div>
					</div>
				</span>

				<Br />

				{
					rol === "Supervisor"
						? null
						:
						(
							<div className="container_role_form">


								<section className="row__technician__create" style={{ marginBottom: "6px" }}>
									<b className='input_form_technicials' style={{ marginRight: 50 }}>¿Asignar Rol Supervisor a técnico?</b>
									<span className='switch_box'>
										<b className='input_form_technicials'>No</b>
										<Switch style={{ margin: "0 5px" }} onChange={(checked) => {
											setShowRol(!showRol)
											if (checked) {
												setSelectionRole(0)
											}

											console.log(checked)
										}} />
										<b className='input_form_technicials'>Si</b>
									</span>
								</section>
							</div>
						)
				}

				{/* ROL */}
				{showRol ? (
					<>
						<LabelDrawer value="Técnicos" />

						{/* personalizacion del multiple select */}
						<section className="container_technicials_role_form">
							<Select
								className='input_form_technicials select__custom__style'
								mode="multiple"
								placeholder="Selecciona un Técnico"
								onChange={handleChange}
							>

								{technicians?.map((item) =>
								(
									<Option key={item?.ID} value={item?.ID}>
										{item?.name} {item?.last_name}
									</Option>
								))}
							</Select>
						</section>
					</>
				) : (
					<section className="container_technicials_role_form">
						<LabelDrawer value="Rol del técnico " />
						<Select
							placeholder="Seleccione un rol"
							options={enumerableRole}
							onChange={handleChangeRole}
							status={inputStatus?.input9}
						/>
					</section>
				)}

				<Br />

				<SubtitleBlue value="Credenciales aplicación movil" />

				<span className="row__technician__create space-between">
					<div className="left">
						<div className="column">
							<LabelDrawer value="Correo electrónico" />
							<Input
								className='input_form_technicials'
								style={{ height: "40px" }}
								type="text"
								onChange={(e) => setEmail(e.target.value)}
								status={inputStatus?.input7}
							/>
						</div>

						<span style={{ color: errorRed, visibility: emailError ? "visible" : "hidden" }}>{emailError}</span>

					</div>
					<div className="right">
						<div className="column">

							<LabelDrawer value="Contraseña" />
							<Input.Password
								className='input_form_technicials'
								iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
								onChange={onPasswordChange}
								style={{ minHeight: 40 }}
								status={inputStatus.input8}
								maxLength={20}
								minLength={8}
							/>

						</div>
					</div>
				</span>

				<Br />
				{/* ocultar si rol = supervisor */}

				<section className='container_important'>
					<span className='title_important'>
						<SubtitleBlue value="Importante " />
					</span>
					<Container_Checkbox>
						<Checkbox
							className='checkbox_form'
							onChange={(e) => {
								setShowSchedule(e.target.checked)
								console.log(e.target.checked)
							}}
						>
							Permitir agendar en el mismo día en que se generen o se reagenden ordenes de servicio
						</Checkbox>
					</Container_Checkbox>
				</section>



				<BottomSection onCancel={onCloseModal} onAcept={onCreateTechnician} />
			</Form>
		</CustomModal>
	)
}

export default NewTechnicianForm

const Container_Checkbox = styled.div`
.ant-checkbox+span{
	padding-inline-end: 0;
}

.ant-checkbox-wrapper{
	font-size: 12px;
}

.checkbox_form{
	margin-bottom: 25px;
}

@media screen and (min-width: 1280px){
	.ant-checkbox-wrapper{
		font-size: 12px;
	}
}

@media screen and (min-width: 1366px){
	.ant-checkbox-wrapper{
		font-size: 13.5px;
	}
}

@media screen and (min-width: 1920px){
	.ant-checkbox-wrapper{
		font-size: 15.18px;
	}
}
`