/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Switch, DatePicker, Select, notification, Checkbox } from 'antd';
import styled from 'styled-components';
import InputSearch from './InputSearch/InputSearch';
import Br from '../../../components/br/Br.jsx';
import { getCategory } from '../../../api/category';
import { getAvailability, getSegmentedHour } from '../../../api/available_hours';
import { getID_type } from '../../../api/technician';
import color from '../../../helpers/color';
import BlueResumeUI from '../../../components/BlueResumeUI/BlueResumeUI';
import SubtitleBlue from '../../../components/text/drawer/subtitleBlue';
import MainTitle from '../../../components/text/drawer/mainTitle';
import LabelDrawer from '../../../components/text/drawer/label';
import BottomSection from '../../../components/form/bottomSection';
import { checkEmptyKeys, emptyInputsCheck, validarCampos } from '../../../helpers/helpers';
import { postNewServiceOrder } from '../../../api/calendar';
import Form from '../../../components/form/index.jsx';
import { devices, largeSizes, mediumSizes, smallSizes } from '../../../helpers/sizes';
import ViewPlanSelect from './viewPlan/ViewPlanSelect';
import useDisabledButton from '../../../hooks/useDisabledButton';
import ManualPlanSearch from './manualPlanSearch/ManualPlanSearch';
import { myFetchGet } from '../../../services/services';
import { useSelector } from 'react-redux';

export default function FormularioOS(
	{
		setVisible,
		filtreValue,
		setFiltreValue,
		typeOSList,
		loginOperadorID,
		showSpecification,
		setVisibleFormOverflow,
		visibleFormOverflow,
		setNameTechnican,
		setRandomTechnican,
		setDateSelectedForm,
		setCategorySelected,
		selectedHoursOverflow,
		disponibilidadIDOverflow,
		technicianOverflow,
		setTechnicianIDForm,
		setSelectedHoursOverflow,
		setDisponibilidadIDOverflow,
		setTechnicianOverflow,
	}) {
	const [idTypes, setIdTypes] = useState([])
	const [OStype, setOStype] = useState(null)

	const [categories, setCategoryList] = useState([])
	const [category, setCategory] = useState(null)

	const [showTechnician, setShowTechnician] = useState(false)

	// selected user or manual user
	const [titularUser, setTitularUser] = useState({})

	const [date, setDate] = useState(null)

	const [availableHours, setAvailableHours] = useState([])
	const [selectedHour, setSelectHour] = useState(null)

	const [disponibilidadID, setDisponibilidadID] = useState(null)

	const [technicianID, setTechnicianID] = useState(null)
	const [selectedTechnicianName, setSelectedTechnicianName] = useState("")

	const [tradename, setTradename] = useState("")
	const { Option } = Select;

	// --- Manual register ---
	const [manualRegister, setManualRegister] = useState(false)
	const [name, setName] = useState(null)
	const [last_name, setLast_name] = useState(null)
	const [documentType, setDocumentType] = useState(null)

	const [documentNumber, setDocumentNumber] = useState(null)
	const [phone, setPhone] = useState(null)
	const [email, setEmail] = useState(null)
	const [idServices, setIdServices] = useState(null)
	const [address, setAddress] = useState('')

	const [selectUserSearch, setSelectUserSearch] = useState(false)
	const [availableTechnicians, setAvailableTechnicians] = useState([])
	const [selectedDates, setSelectedDates] = useState([]);
	const [selectedDirectionId, setSelectedDirectionId] = useState('');
	const [selectedTechnicials, setSelectedTechnicials] = useState(null);
	const [selectedDirectionName, setSelectedDirectionName] = useState('');

	const [dateSelected, setDateSelected] = useState(null);
	const [supervisorSupport, setSupervisorSupport] = useState(false);
	const [rolePermissions, setRolePermissions] = useState(false);



	const { enableButton, disableButton, isButtonDisabled } = useDisabledButton()

	const dataRedux = useSelector((state) => state.authState)

	const onCloseModal = () => setVisible(false)

	// metodo para obtener el id del tecnico y la data de las fechas
	const onTechnicianChange = (value) => {

		setSelectedTechnicials(value);

		const selectedId = value;
		// filtro por para seleccionar las fechas del tecnico 
		const selectedData = availableTechnicians.find(item => item.id === selectedId);
		// cargar id
		setTechnicianID(selectedId);
		//  cargar fechas
		setSelectedDates(selectedData.dates);
		// carga Nombre de tecnico	
		setSelectedTechnicianName(selectedData.name);
		// datos enviados a form Overflow
		setNameTechnican(selectedData.name);
		setTechnicianIDForm(selectedId)
	}

	const onTypeOSChange = (value) => {
		setOStype(value);
		setCategory(null);
	}

	const onCategoryChange = (value) => {
		setCategory(value);
		setCategorySelected(value);
	}

	const onDateChange = (dates, dateString) => {
		setDateSelected(dates);
		setDate(dateString);
		setDateSelectedForm(dateString);
	}

	const onHoursChange = (value) => {
		if (value === 'overflow') {
			;
			setVisibleFormOverflow(true);
		} else {
			const toJSON = JSON.parse(value)

			setSelectHour(toJSON?.hours)
			getSegmentedHour(toJSON?.disponibilidad, toJSON?.end_time, toJSON?.start_time, date)
				.then(res => {
					setDisponibilidadID(res?.id_disponibility_created)
				})
				.catch(e => console.log("e", e))
		}
	}

	const [camposIncompletos, setCamposIncompletos] = useState(null)

	const onServiceOrderCreate = async () => {
		let data = {

			technician: technicianID || technicianOverflow?.id,
			status: 0,
			ostype: OStype,
			category: category,
			operator: loginOperadorID,
			disponibility_hours: disponibilidadID || disponibilidadIDOverflow,
			supervisor_required: supervisorSupport,
		};

		if (showSpecification) {
			if (manualRegister) {
				data.first_name = name;
				data.last_name = last_name;
				data.document_type = documentType;
				data.document_number = documentNumber;
				data.phone = phone;
				data.email = email;
				data.service_number = idServices;
				data.direction = selectedDirectionId;
				data.direction_text = address;
				data.operator_id = loginOperadorID;
				data.tradename = tradename.tradename;
				data.technology = tradename.technology;


			} else {
				data.user_id = titularUser.user_id;
				data.plan_id = titularUser.plan_id;
				data.direction = titularUser.direction_id;
				// data.direction_text = '';
				data.technology = titularUser.plan_technology
			}
		} else {
			if (manualRegister) {
				data.tradename = tradename.tradename;
				data.first_name = name;
				data.last_name = last_name;
				data.document_type = documentType;
				data.document_number = documentNumber;
				data.phone = phone;
				data.email = email;
				data.service_number = idServices;
				data.direction = selectedDirectionId;
				data.direction_text = address;
				data.operator_id = loginOperadorID;
				data.technology = tradename.technology;
			} else {
				data.user_id = titularUser.brujula_user_id;
				titularUser.plan_name && (data.tradename = titularUser.plan_name) || (data.tradename = "Sin plan");
				data.direction = titularUser.direction_id;
				data.direction_text = titularUser.direction;
				titularUser.plan_technology && (data.technology = titularUser.plan_technology) || (data.technology = "technology");
			}
		}

		const clavesVacias = checkEmptyKeys(data);
		setCamposIncompletos(clavesVacias)

		if (validarCampos(data) === false) {
			notification.warning({
				style: { fontWeight: 'bold' },
				message: "Por favor, completa todos los campos",
				placement: 'bottomLeft',
				duration: 3,
			})
		} else {
			disableButton()
			postNewServiceOrder(data).then(res => {
				if (res === "error") {
					enableButton()
				}
			})
		}
	}

	// peticion get para obtener los tecnicos
	const getTechnicials = async (manual) => {
		const responseTechnicials = await myFetchGet(`api/v1/schedule/category_match/?category=${category}&manual=${manual}`)
		setAvailableTechnicians(responseTechnicials.data);
	}

	// effect para obtener las fechas del tecnico y seleccion de tecnico aleatorio
	useEffect(() => {
		if (showTechnician === true) {
			setSelectedDates(availableTechnicians[0].dates)
			// cargar tecnico id
			setTechnicianID(availableTechnicians[0].id)

			// carga Nombre de tecnico	
			setSelectedTechnicianName(availableTechnicians[0].name);
		}
	}, [availableTechnicians])


	// effect para obtener los tecnicos sea forma manual o aleatoria
	useEffect(() => {
		if (showTechnician === false && category) {
			getTechnicials(0);
		} else if (showTechnician === true && category) {
			getTechnicials(1);
		}
	}, [category, showTechnician])


	useEffect(() => {
		const countryID = localStorage.getItem('countryID')

		OStype && getCategory(OStype).then(res => setCategoryList(res))
		countryID && getID_type(countryID).then(res => setIdTypes(res))
	}, [OStype])

	// effect para validar disponibilidad
	useEffect(() => {
		if (date === '' || date === null) {
			setAvailableHours([])
			setSelectHour(null)
			setDisponibilidadID(null)
		}
		date && getAvailability(showTechnician, date, category, technicianID).then(res => setAvailableHours(res));
	}, [date, category, technicianID])

	// Valores Iniciales al cambiar tipo de os o categoria
	useEffect(() => {
		setSelectedTechnicials(null);
		setTechnicianID(null);
		setSelectedTechnicianName("");
		setSelectedDates([])
		setSelectHour(null)
		setDisponibilidadID(null)
		setDate(null)
		setDateSelected(null)
		// Reset valores de form overflow
		setSelectedHoursOverflow(null)
		setDisponibilidadIDOverflow(null)
		setTechnicianOverflow(null)
	}, [OStype, category])

	// logica de sobre escribir null a selectedHour y abrir overflow
	useEffect(() => {
		if (visibleFormOverflow === false) {
			setSelectHour(null)
		}
	}, [visibleFormOverflow])

	useEffect(() => {
		if (showTechnician === false) {

			//  quitar la seleccion de fecha
			setSelectedDates([])
			setDate(null)
			setDateSelected(null)
			setDateSelectedForm(null)
			// quitar seleccion de horario
			setSelectHour(null)

			// Reset valores de form overflow
			setSelectedHoursOverflow(null)
			setDisponibilidadIDOverflow(null)
			setTechnicianOverflow(null)
		} else {
			// quitar seleccion de tecnico
			setTechnicianID(null);
			setSelectedTechnicianName("");
			setSelectedTechnicials(null);

			//  quitar la seleccion de fecha
			setDateSelectedForm(null)
			setDate(null)
			setDateSelected(null)

			// quitar seleccion de horario
			setSelectHour(null)

			// Reset valores de form overflow
			setSelectedHoursOverflow(null)
			setDisponibilidadIDOverflow(null)
			setTechnicianOverflow(null)
		}

	}, [showTechnician])

	useEffect(() => {
		if (dataRedux.rol !== 'client_service_isp') {
			setRolePermissions(true);
		} else {
			setRolePermissions(false);
		}
	}, [dataRedux])



	return (
		<Form>
			<Container>
				<MainTitle
					value="Agregar orden de servicio"
					onClick={() => setVisible(false)}
				/>

				<SubtitleBlue value="Datos personales" />
				<InputSearch
					setFiltreValue={setFiltreValue}
					filtreValue={filtreValue}
					loginOperadorID={loginOperadorID}
					dataUser={setTitularUser}
					idTypes={idTypes}
					// -- manual register----
					setManualRegister={setManualRegister}
					setName={setName}
					setLast_name={setLast_name}
					setDocumentType={setDocumentType}
					setDocumentNumber={setDocumentNumber}
					setPhone={setPhone}
					setEmail={setEmail}
					setIdServices={setIdServices}
					setAddress={setAddress}
					camposIncompletos={camposIncompletos}
					setSelectUserSearch={setSelectUserSearch}
					category={category}

					setSelectedDirectionId={setSelectedDirectionId}
					setSelectedDirectionName={setSelectedDirectionName}
				/>

				<Br />

				{selectUserSearch && < ViewPlanSelect
					data={titularUser}
					showSpecification={showSpecification}
				/>}

				<SubtitleBlue value="Tipo de orden de servicio" />

				<div className='row space-between'>
					<div className='column flex'>
						<LabelDrawer value="Tipo de orden de servicio" />
						<Select
							style={{ width: "97%" }}
							onChange={onTypeOSChange}
							placeholder="Seleccionar"
							status={emptyInputsCheck(camposIncompletos, "ostype") ? "error" : ""}
						>
							{typeOSList?.map(item => <Option key={item?.ID} value={item?.ID}>{item?.name}</Option>)}
						</Select>
					</div>

					<div className='column flex'>
						<LabelDrawer value="Categoría" />
						<Select
							onChange={onCategoryChange}
							placeholder="Seleccionar"
							value={category}
							status={emptyInputsCheck(camposIncompletos, "category") ? "error" : ""}
						>
							{categories?.map(item => <Option key={item.ID} value={item.ID}>{item.name}</Option>)}
						</Select>
					</div>
				</div>

				<Br />

				{/* ----------PLAN--------------- */}

				{selectUserSearch === false && manualRegister &&
					(
						<ManualPlanSearch
							loginOperadorID={loginOperadorID}
							setTradename={setTradename}
							camposIncompletos={camposIncompletos}
							showSpecification={showSpecification}
						/>
					)}

				<Br />
				{/* ----------Specification------ */}

				<div className="switch_container_os">

					<div className="left">
						<span className="switch_box">
							<span className='text-assignment'>¿Asignar de forma aleatoria técnico principal?</span>

							<span className='text'>No</span>
							<Switch style={{ margin: "0 5px" }} onChange={(e) => {
								setShowTechnician(!showTechnician);
								setRandomTechnican(!showTechnician);
							}} />
							<span className='text'>Si</span>
						</span>



					</div>

					<div className="right">
						{
							!showTechnician && (
								<div className="showable_technician">
									<div className="column">
										<span style={{ display: 'flex', justifyContent: 'space-between' }}>
											<LabelDrawer value="Técnico" />
											<Checkbox className='checkbox_os' onChange={(e) => setSupervisorSupport(e.target.checked)}>Amerita apoyo del supervisor</Checkbox>
										</span>

										<Select
											onChange={onTechnicianChange}
											placeholder="Seleccionar"
											value={selectedTechnicials}
											status={emptyInputsCheck(camposIncompletos, "technician") ? "error" : ""}
										>
											{availableTechnicians?.map(item => <Option key={item?.id} value={item?.id}>{item?.name}</Option>)}
										</Select>
									</div>
								</div>
							)
						}
					</div>
				</div>

				<Br />

				<SubtitleBlue value="Agendamiento" />
				<div className='row'>
					<div className="column flex">
						<LabelDrawer value="Fecha" />
						<DatePicker
							inputReadOnly
							placeholder="Seleccionar"
							style={{ width: "97%" }}
							format="DD-MM-YYYY"
							onChange={onDateChange}
							value={dateSelected}
							status={emptyInputsCheck(camposIncompletos, "disponibility_hours") ? "error" : ""}
							// Se habilita fechas por disponibilidad
							disabledDate={(current) => {
								return selectedDates.indexOf(current.format('YYYY-MM-DD')) === -1;
							}}
						/>
					</div>

					<div className="column flex">
						<LabelDrawer value="Horario" />
						<Select
							onChange={onHoursChange}
							placeholder="Seleccionar"
							value={selectedHoursOverflow === null ? selectedHour : selectedHoursOverflow}
							status={emptyInputsCheck(camposIncompletos, "disponibility_hours") ? "error" : ""}
						>
							{availableHours?.map((item, i) =>
								<Option key={i} value={JSON.stringify(item)}>
									{item.hours}
								</Option>)
							}
							{availableHours?.length > 0 && rolePermissions === true
								? <Option style={{ color: '#2B80FF', fontWeight: 'bold' }} value={'overflow'}>Agendar sobre cupo</Option>
								: null
							}

						</Select>
					</div>
				</div>
				<Br />
				{
					(disponibilidadID || disponibilidadIDOverflow) && (
						<BlueResumeUI>
							<span style={{ marginBottom: 10 }}></span>
							<SubtitleBlue color='#004085' value="Resumen de solicitud" />

							<p className='text-resumen margin-bottom-6' ><b>Técnico:</b> {technicianOverflow !== null ? technicianOverflow?.full_name : selectedTechnicianName || "-"} </p>
							<div className='container_text_summary'>
								<p className='text-resumen margin-bottom-6'><b>Fecha:</b> {date && date} </p>
								<p className='text-resumen margin-bottom-6'><b>Hora:</b> {selectedHoursOverflow === null ? selectedHour || "-" : selectedHoursOverflow} </p>
							</div>
							<p className='text-resumen margin-bottom-6' ><b>Titular del servicio:</b> {manualRegister === false ? titularUser?.first_name + ' ' + titularUser?.last_name : name + ' ' + last_name}</p>
							<p className='text-resumen margin-bottom-6'><b>Documento de identidad:</b> {manualRegister === false ? titularUser?.document_number : documentNumber}</p>
							<p className='text-resumen margin-bottom-6'><b>Dirección:</b> {manualRegister === false ? titularUser?.direction : selectedDirectionName} </p>
						</BlueResumeUI>
					)
				}
				<BottomSection
					onCancel={onCloseModal}
					onAcept={onServiceOrderCreate}
					isButtonDisabled={isButtonDisabled}
				/>
			</Container >
		</Form>
	)

}


const Container = styled.div`

  .row{
    margin: 0 !important;
    align-items: center;
    margin-top: 5px;
  }

  .row_center{
    justify-content: center;
  }

  .row_end{
    justify-content: flex-end;
  }

  .space-between{
    justify-content: space-between;
    margin: 0 !important;
  }

  .column{
    display:flex;
    flex-flow: column;
    margin: 0 !important;
  }

  .column_center{
    justify-content: center;
  }

  .column_end{
    justify-content: flex-end;
  }

  .flex{
    flex: 1
  }

  .switch_container_os{
    width: 100%;
    max-height: 120px;
    display: flex;
    background: white;
		margin-top: 25px;
		flex-direction: column;
  }

  .switch_container_os .left{
    width: 100%;
		display: flex;
		align-items: center;
		margin-bottom: 18px;
  }

  .switch_container_os .right{
    width: 100%;
  }

	.checkbox_os{
		display: flex;
    align-items: center;
		color: #2B80FF;
		font-size: ${smallSizes.fontSize.fontSize12};
		font-weight: bold;
	}

  .switch_box{
    padding-right: 30px;
		display: flex;
  }

	.switch_box .text{
		font-size: ${smallSizes.fontSize.fontSize10}
	}

  .subtitled{
    font-size: 14px;
    font-weight: bold;
    color: ${color.requiredIconBlue};
  }

  .breadcum{
    color: #004085;
    background: #CCE5FF;
    width: 100%;
    height: 166px;
    border: 1px solid #B8DAFF;
    border-radius: 4px;
  }


// ---------------------------------------
// ---------------------------------------

.ant-select:not(.ant-select-customize-input) 
.ant-select-selector {
    position: relative;
    background-color: white !important;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    color: #868D96; 
    font-size: 12px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select-single:not(.ant-select-customize-input) 
.ant-select-selector {
    width: 100%;
    height: 40px;
    color: #868D96; 
    font-size: ${smallSizes.fontSize.fontSize12};
}
.ant-select-item {
    position: relative;
    display: block;
    min-height: 40px;
    padding: 5px 12px;
    color: #868D96; 
    font-size: 12px;
    font-weight: normal;
    line-height: 22px;
    cursor: pointer;
    
}
.ant-select-single:not(.ant-select-customize-input),
.ant-select-selector
.ant-select-selection-search-input {
    height: 40px;
}
.ant-picker {
    color: #868D96; 
    font-size: 12px;
    padding: 4px 11px 4px;
    position: relative;
    display: inline-flex;
    align-items: center;
    background: white;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    height: 40px;
}
.ant-select-item-option-content {
    color: #868D96; 
    font-size: 12px;
    padding: 4px 11px 4px;
    background: white;
}

.ant-picker-suffix {
    display: flex;
    flex: none;
    align-self: center;
    margin-left: 4px;
    color: #949594;
    line-height: 1;
    pointer-events: none;
}
.ant-picker-input > input {
    color: #949594;
    // font-size: 12px;
    background-color: white;
    border-radius: 5px;
}
.margin-bottom{
  margin-bottom: 11px;
}

.ant-select-multiple > div:nth-child(1) {
  height: 40px
	}

.ant-select-multiple .ant-select-selection-item {
	font-size: ${smallSizes.fontSize.fontSize12};
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector{
	font-size: ${smallSizes.fontSize.fontSize12};
}


// ---------------------------------------
// ---------------------------------------

.modal_input {
	width: 100%;
	height: 38px;
	background: white;
	border-radius: 5px;
	border: 1px solid #d9d9d9;
	outline: 0;
	padding: 4px 11px 4px;
	margin-top: 0.5em;
	font-size: 12px;

	:focus {
		border-color: #426da9;
		box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
		border-right-width: 1px;
	}

}

.text-assignment{
	color: #131523;
	font-size: ${smallSizes.fontSize.fontSize12};
	font-weight: bold;
	margin-right: 17px;
}

.ant-picker .ant-picker-input > input{
	font-size: ${smallSizes.fontSize.fontSize12};
}

.text-resumen{
	font-size: ${smallSizes.fontSize.fontSize12};
	color: #004085;
}

.margin-bottom-6{
	margin-bottom: 6px;
}

.container_text_summary{
	display: flex;
	justify-content: space-between;
	width: 260px;
}

@media screen and (min-width: ${devices.smallDesk}){

	.text-assignment{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.checkbox_os{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.switch_box .text{
		font-size: ${smallSizes.fontSize.fontSize10}
	}

	.ant-select-single:not(.ant-select-customize-input)
	.ant-select-selector {
    font-size: ${smallSizes.fontSize.fontSize12};
	}

	.ant-select-multiple .ant-select-selection-item {
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.ant-picker .ant-picker-input > input{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.text-resumen{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
	.container_text_summary{
		width: 260px;
	}
}

@media screen and (min-width: ${devices.mediumDesk}){

	.text-assignment{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.checkbox_os{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.switch_box .text{
		font-size: ${mediumSizes.fontSize.fontSize10}
	}

	.ant-select-single:not(.ant-select-customize-input)
	.ant-select-selector {
    font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.ant-select-multiple .ant-select-selection-item {
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.ant-picker .ant-picker-input > input{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.text-resumen{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.container_text_summary{
		width: 290px;
	}
}

@media screen and (min-width: ${devices.largeDesk}){

	.text-assignment{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.checkbox_os{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.switch_box .text{
		font-size: ${largeSizes.fontSize.fontSize10}
	}

	.ant-select-single:not(.ant-select-customize-input)
	.ant-select-selector {
    font-size: ${largeSizes.fontSize.fontSize12};
	}

	.ant-select-multiple .ant-select-selection-item {
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.ant-picker .ant-picker-input > input{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.text-resumen{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.container_text_summary{
		width: 325px;
	}
}
`
