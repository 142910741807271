import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';

import CustomModal from '../../../../../components/modal/CustomModal.jsx';
import { Input, Popconfirm, Select, Switch, notification } from 'antd';

import Br from '../../../../../components/br/Br.jsx';
import { onUploadOspic } from '../../../../../api/imageGallery.js';
import Form from '../../../../../components/form/index.jsx';
import MainTitle from '../../../../../components/text/drawer/mainTitle/index.jsx';
import BottomSection from '../../../../../components/form/bottomSection/index.jsx';
import { devices, largeSizes, mediumSizes, smallSizes } from '../../../../../helpers/sizes.js';
import LabelDrawer from '../../../../../components/text/drawer/label/index.jsx';
import SubtitleBlue from '../../../../../components/text/drawer/subtitleBlue/index.jsx';

import style from './uploadImageForm.module.css'

const UploadImageForm = ({ closeUploadForm, ID, listCategory, categoryMatch }) => {

	const [caption, setCaption] = useState(null)
	const [photo, setTempPhoto] = useState(null)
	const [base64Img, setBase64Img] = useState(null)
	const [visibleSelect, setVisibleSelect] = useState(false)
	const [options, setOptions] = useState(null)

	const [categoryDisable, setCategoryDisable] = useState(false)

	const clearTempImage = () => {
		setTempPhoto(null)
		setBase64Img(null)
	}

	const closeForm = () => {
		closeUploadForm()
		window.location.reload()
	}

	const inputFile = useRef()
	const data = {
		"caption": caption,
		"photo_base64": base64Img,
		"owner_os": ID,
		"updated_image": true
	}

	const passingToBase64 = (archivos) => {
		setTempPhoto(archivos[0])
		Array.from(archivos).forEach(archivo => {

			const reader = new FileReader();
			reader.readAsDataURL(archivo);
			reader.onload = function () {
				let arraySplited = []
				const base64 = reader.result;

				arraySplited = base64.split(',');
				setBase64Img(arraySplited[1])
			}
		})
	}

	const hidden = { display: "none" }

	const onSelectCategory = (value) => {
		setCaption(value)

		if (categoryMatch[value] === 5) {
			setCategoryDisable(true)
		} else {
			setCategoryDisable(false)
		}
	}

	const updateImage = () => {
		if (!categoryDisable) {
			onUploadOspic(closeForm, data)
		} else {
			notification.warning({
				style: { fontWeight: 'bold' },
				description: 'El maximo de imagenes para esta categoria es de 5',
				placement: 'bottomLeft',
				duration: 2,
			});
		}
	}

	useEffect(() => {
		const option = listCategory?.map(item => {
			return { label: item.img_name, value: item.img_name }
		})
		setOptions(option)
	}, [])


	return (
		<CustomModal onClick={closeUploadForm}>
			<Form>
				<Container>
					<MainTitle
						value='Subir archivo'
						onClick={closeUploadForm}
					/>

					<SubtitleBlue value='Información general' />

					<section className={style.container_switch} >
						<p className={style.title_switch}>¿Imagen asociada a la categoria?</p>
						<span className={style.subcontainer_switch}>
							<p className={style.text_swicth}>No</p>
							<Switch style={{ margin: "0 5px" }} onClick={(checked) => setVisibleSelect(checked)} />
							<p className={style.text_swicth}>Si</p>
						</span>
					</section>

					{!visibleSelect ? (
						<div className={style.container_inpt_select}>
							<LabelDrawer value='Nombre de la imagen' />
							<Input
								className="input"
								style={{ height: "40px" }}
								placeholder='Ingrese el nombre de la imagen'
								onChange={e => setCaption(e.target.value)}
							/>
						</div>

					) : (
						<div className={style.container_inpt_select}>
							<LabelDrawer value='Imágenes' />
							<Select
								style={{ height: "40px" }}
								placeholder='Seleccionar'
								options={options}
								onChange={onSelectCategory}
							/>
						</div>
					)}

					<button
						className="uploader_box"
						onClick={() => inputFile.current.click()}
					>
						<i className="fa-solid fa-upload icon_size_button" />
						<span className='subtitle_button'>Haga click o arrastre el archivo a esta área para cargarlo</span>
						<span className='help_button'>Soporte para una carga única o masiva. Prohibido estrictamente</span>
						<span className='help_button'>cargar datos de la empresa u otros archivos de bandas.</span>
						<input type="file" ref={inputFile} onChange={(e) => passingToBase64(e.target.files)} style={hidden} />
					</button>

					<Br />
					<Br />

					{
						base64Img &&
						<div className='temp_image_box'>
							<img className='temp_image animate__animated animate__fadeIn' src={`data:image/png;base64,${base64Img}`} alt="Temporal selected base64" />
							<p className="animate__animated animate__fadeIn temp_name">{photo?.name}</p>

							<Popconfirm
								placement="topRight"
								icon={""}
								title="¿Eliminar fotografía?"
								description={() => {
									return (
										`Si eliminas esta fotografía, se eliminará de forma permanente de tu galería de imágenes`)
								}}
								onConfirm={clearTempImage}
								okText="Eliminar"
								cancelText="Cancelar"
							>
								<i className="fa-solid fa-trash clear_tempImageIcon" ></i>
							</Popconfirm>
						</div>
					}

					<BottomSection onCancel={closeUploadForm} onAcept={updateImage} minHeight={205} />

				</Container>
			</Form>

		</CustomModal>
	)
}

export default UploadImageForm

const Container = styled.div`

p{display: inline;margin: inherit}

.row {
	margin: inherit;
	display: flex;
	align-items: center;
}

.column {
	display: flex;
	flex-flow: column;
}
.align-item-center{
    align-items: center;
}
.row-center{
    justify-content: center;
}
.flex-end{
    justify-content: flex-end;
}
.space-between{
    justify-content: space-between;
}
.column_center{
    justify-content: center;
}

.subtitle_button{
    font-size: ${smallSizes.fontSize.fontSize14};
    font-weight: bold;
    color: #2B80FF;
    margin-bottom: 1em;
}

.blue{color: #2B80FF}

.uploader_box {
	display: flex;
	flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  border: none;
  outline: none;
  border-radius: 3px;
  border: 1px dashed silver;
  background: white;
  padding: 1em;
  cursor: pointer;

}
.uploader_box:hover {
	border: 1px dashed #d9d9d9;
	background: #fafafa;
}

.temp_image_box {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #d9d9d9;
  padding: 1em;
  display: flex;
  align-items: center;
}
.temp_image {
  width: 116px;
  height: 76px;
  border-radius: 3px;
  object-fit: cover;
}
.clear_tempImageIcon {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #2B80FF;
  border-radius: 2px;
  cursor: pointer;

  :hover {color: grey}
}

.uploader_box .icon_size_button{
	font-size: ${smallSizes.fontSize.fontSize36};
	color: #DDE4EB;
	margin-bottom: 10px;
}

.uploader_box .help_button{
	color: silver;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.temp_image_box .temp_name{
	color: #5A607F;
	padding: 0 10px;
	flex: 1;
	font-weight: bold;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.input{
	font-size: ${smallSizes.fontSize.fontSize12};
}

//------------------------------------------------------

@media screen and (min-width: ${devices.smallDesk}){
	.subtitle_button{
    font-size: ${smallSizes.fontSize.fontSize14};
	}

	.uploader_box .icon_size_button{
		font-size: ${smallSizes.fontSize.fontSize36};
	}

	.uploader_box .help_button{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.temp_image_box .temp_name{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.input{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

}

@media screen and (min-width: ${devices.mediumDesk}){
	.subtitle_button{
    font-size: ${mediumSizes.fontSize.fontSize14};
	}

	.uploader_box .icon_size_button{
		font-size: ${mediumSizes.fontSize.fontSize36};
	}

	.uploader_box .help_button{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.temp_image_box .temp_name{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.input{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

}

@media screen and (min-width: ${devices.largeDesk}){
	.subtitle_button{
    font-size: ${largeSizes.fontSize.fontSize14};
	}

	.uploader_box .icon_size_button{
		font-size: ${largeSizes.fontSize.fontSize36};
	}

	.uploader_box .help_button{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.temp_image_box .temp_name{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.input{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

}
`


