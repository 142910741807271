import React from "react";

import Login from "../pages/login";
import TypesServiceOrders from "../pages/typesServiceOrders/TypesServiceOrders";
import Technicians from "../pages/technicians/index.jsx";
import TechniciansDetails from "../pages/techniciansDetails/TechniciansDetails";
import Absences from "../pages/absences/Absences";
import TypesServiceOrdersDetails from "../pages/typesServiceOrdersDetails/TypesServiceOrdersDetails";
import ServiceOrdersList from "../pages/serviceOrdersList/ServiceOrdersList";
import ServiceOrdersDetails from "../pages/serviceOrdersDetails/ServiceOrdersDetails";
import ServiceOrdersCalendar from "../pages/serviceOrdersCalendar/ServiceOrdersCalendar";
import Availability from "../pages/availability/Availability";
import Dashboard from "../pages/home/Dashboard";
import Users from "../pages/users";
import RoutesReport from "../pages/routesReport";
import Holidays from "../pages/holidays/Holidays.jsx";

const routes = [
	// paht Auth
	{
		path: "/",
		title: "Login",
		exact: true,
		private: false,
		component: () => <Login />,
	},

	// orden de servicios
	{
		path: "/dashboard",
		exact: true,
		private: true,
		title: "Dashboard",
		component: () => <Dashboard />,
	},

	{
		path: "/listServiceOrders",
		exact: true,
		private: true,
		title: "Listado de órdenes de servicio",
		component: () => <ServiceOrdersList />,
	},

	{
		path: "/calendarServiceOrders",
		exact: true,
		private: true,
		title: "Calendario de órdenes de servicio",
		component: () => <ServiceOrdersCalendar />,
	},

	{
		path: "/serviceOrdersDetails",
		exact: true,
		private: true,
		title: "Detalles de orden de servicio",
		component: () => <ServiceOrdersDetails />,
	},

	// tipos de ordenes de servicio

	{
		path: "/TypesOS",
		exact: true,
		private: true,
		title: "Tipos de órdenes de servicios",
		component: () => <TypesServiceOrders />,
	},
	{
		path: "/TypeDetallesOS",
		exact: true,
		private: true,
		title: "Detalles de órdenes de servicios",
		component: () => <TypesServiceOrdersDetails />,
	},

	// Disponibilidad

	{
		path: "/technicians",
		exact: true,
		private: true,
		title: "Técnicos",
		component: () => <Technicians />,
	},

	{
		path: "/availability",
		exact: true,
		private: true,
		title: "Disponibilidad",
		component: () => <Availability />,
	},

	{
		path: "/techniciansDetails",
		exact: true,
		private: true,
		title: "Detalle Técnicos",
		component: () => <TechniciansDetails />,
	},

	{
		path: "/absences",
		exact: true,
		private: true,
		title: "Ausencias",
		component: () => <Absences />,
	},
	{
		path: "/holidays",
		exact: true,
		private: true,
		title: "Feriados",
		component: () => <Holidays />,
	},

	// RoutesReport
	{
		path: "/routesReport",
		exact: true,
		private: true,
		title: 'Reporte de rutas',
		component: () => <RoutesReport />,
	},


	// Settings
	{
		path: "/users",
		exact: true,
		private: true,
		title: "Usuarios",
		component: () => <Users />,
	},
];

export default routes;
