import React, { useEffect, useState } from 'react'

// Api
import { patchStatusAndTechnicianOS, getAvailableStatus, getAvailableTechnicians, getAssistant } from "../../../../api/calendar.js"

// helper
import { permissionCheck } from '../../../../helpers/helpers.js';
import UseOperator from "../../../../hooks/UseOperator.jsx";
import { devices, largeSizes, mediumSizes, smallSizes } from "../../../../helpers/sizes.js";
import { selectTab } from '../../../../store/tab/tabSlice.js';

// custom component
import SubtitleBlue from "../../../../components/text/drawer/subtitleBlue/index.jsx";
import Br from '../../../../components/br/Br.jsx';
import BottomSection from '../../../../components/form/bottomSection/index.jsx';
import { okBtnStatus } from "./index.js";

// external libraries
import { Tooltip, Divider, Select, Switch, notification, Space } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import ReactSelect from 'react-select';
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import RescheduleCalendar from './rescheduleCalendar/RescheduleCalendar.jsx';



const OrdersServicieGeneralPanel = ({ data, setReagendarVisible }) => {

	const [changeTechnician, setChangeTechnician] = useState(false);

	const [showReagendarButton, setShowReagendarButton] = useState(true);
	const [prefixes, setPrefixes] = useState('');

	const [reagendar, setReagendar] = useState(false);

	const [technicians, setTechnicians] = useState([]);
	const [technicanOS, setTechnicanOS] = useState('');
	const [technicianID, setTechnicianID] = useState(data?.technician_id);

	const [assistant, setAssistant] = useState(false);
	const [warningAuxiliaries, setWarningAuxiliaries] = useState(false);
	const [dataWarning, setDataWarning] = useState("");
	const [selecAssistant, setSelecAssistant] = useState([]);
	const [btnSave, setBtnSave] = useState(false);
	const [dataAssistant, setDataAssistant] = useState([]);
	const [valueAssistant, setValueAssistant] = useState([]);
	const [changeStatus, setChangeStatus] = useState(false);
	const [statusID, setStatusID] = useState(data?.status_id);
	const [statusOs, setStatusOs] = useState(data?.status);

	const [allAvailableStatus, setAllAvailableStatus] = useState([])

	const history = useHistory();
	const { storedOperatorID } = UseOperator()
	const dispatch = useDispatch();

	const reduxState = useSelector((state) => state);
	const array = reduxState?.authState?.permissions;

	const statusId = data?.status_id;
	const status = data?.status;
	const categoryID = data?.category_id;

	let updateData = { "status": statusID, "technician": technicianID };

	const rolPermission = (permissionValue) => {
		return permissionCheck(array, permissionValue)
	};

	const onReagendar = () => setReagendar(!reagendar);

	const onTechnicianValueChange = (value) => {
		setTechnicianID(value)
	};

	const onStatusValueChange = (value) => setStatusID(value);

	const onChange = (checked) => {
		if (checked && warningAuxiliaries) {
			notification.warning({
				style: { fontWeight: 'bold' },
				message: dataWarning,
				placement: 'bottomLeft',
				onClose: () => setAssistant(false)
			})
		} else {
			setAssistant(checked)
		}

		if (!checked) {
			setSelecAssistant([]);
			if (data?.auxiliaries.length > 0) {
				setBtnSave(true);
			}
		}
	};

	const AssistantForSelect = dataAssistant?.map(item => {
		return {
			value: item.id,
			label: item.full_name
		}
	});

	const handleSelectChange = (selected) => {
		setValueAssistant(selected);
		setBtnSave(true);
		setSelecAssistant(selected.map(op => op.value));
	};

	const customStyles = {
		multiValue: (base) => ({
			...base,
			backgroundColor: "white",
			color: '#5A607F',
			border: '1px solid #5A607F',
			borderRadius: "2px",
			fontSize: '12px'
		}),
		multiValueLabel: (base) => ({ ...base, color: '#5A607F' }),
		multiValueRemove: (base, state) => ({
			...base,
			backgroundColor: 'white',
			color: '#5A607F',
			':hover': { backgroundColor: "white" },
		}),
		control: (base, state) => ({
			...base,
			borderColor: assistant ? (state.isFocused ? '#4096ff' : '#d9d9d9') : '#ff6365', // Cambia el color del borde en foco
			boxShadow: state.isFocused ? 'none' : base.boxShadow, // Cambia el color del sombreado en foco
		}),
	};

	const patchOS = () => {
		if (assistant) {
			updateData["auxiliaries"] = selecAssistant
		} else {
			updateData["auxiliaries"] = []
		}
		patchStatusAndTechnicianOS(data?.id_unico, updateData).then(res => res === "error" ? null : history.push("/listServiceOrders"))
	};

	const returnPrevStateOnClose = () => {
		setStatusID(data?.status_id)
		setTechnicianID(data?.technician_id)
		setChangeStatus(false)
		setChangeTechnician(false)
		if (data?.auxiliaries.length > 0) {
			setAssistant(true);
			setValueAssistant(data?.auxiliaries.map(item => ({
				value: item.id,
				label: item.full_name
			})));
		} else {
			setAssistant(false)
		}
		setBtnSave(false)
	};

	const showingTabs = () => {
		dispatch(
			selectTab({
				selectedTab: 0,
				showTabs: true,
			})
		)
	};

	const hiddingTabs = () => {
		dispatch(
			selectTab({
				selectedTab: 0,
				showTabs: false,
			})
		)
	};

	useEffect(() => {
		setShowReagendarButton(
			// si el array contiene el btn status que muestra el btn de reagendar
			//arroja true, y muestra el button, statuses incorrectos, NO.
			okBtnStatus.includes(status) ? true : false
		);
	}, [status]);

	useEffect(() => {
		if (data?.user_full.iso === "PE") {
			setPrefixes("+51")
		} else if (data?.user_full.iso === "CL") {
			setPrefixes("+56")
		} else if (data?.user_full.iso === "CO") {
			setPrefixes("+57")
		} else if (data?.user_full.iso === "VE") {
			setPrefixes("+58")
		}
	}, [])

	useEffect(() => {
		data?.id_unico && getAvailableStatus(data?.id_unico).then(res => setAllAvailableStatus(res))

		storedOperatorID && getAvailableTechnicians(storedOperatorID, data?.fecha, categoryID).then(res => setTechnicians(res))
		setStatusOs(data?.status)
		setTechnicanOS(data?.technician)
	}, [storedOperatorID])

	useEffect(() => {
		data?.id_unico && getAssistant(data?.id_unico).then(res => {
			if (res.status === 404) {
				setWarningAuxiliaries(true)
				setDataWarning(res.data.message)
			} else {
				setDataAssistant(res)
			}
		}

		)
	}, [data?.id_unico])

	useEffect(() => {
		if (reagendar) {
			setReagendarVisible(true);
			reagendar && hiddingTabs();
		} else {
			setReagendarVisible(false);
			reagendar === false && showingTabs();
		}
	}, [reagendar])

	// realiza la validacion que si tenemos auxiliares en la Orden de servicio
	useEffect(() => {
		if (data?.auxiliaries.length > 0) {
			setAssistant(true)
			setValueAssistant(data?.auxiliaries.map(item => ({
				value: item.id,
				label: item.full_name
			})));
		}
		setSelecAssistant(data?.auxiliaries.map(item => item.id))
	}, [])

	return (
		<>
			{reagendar === false ? (
				<Container>

					<div className="distribution__container" >
						<div className="service__detail__left">
							<SubtitleBlue value="Detalle orden de servicio" />

							<p className="date__scheduling">Fecha de agendamiento <b style={{ color: '#959595' }}>{data?.fecha}</b></p>

							<div className="service__detail">
								<Tooltip arrow={false} title={() => <label style={{ color: 'black', marginBottom: 0 }}>{data?.ostype.name}</label>} color="white" >
									<label className="status__label">{data?.ostype.name}</label>
								</Tooltip>


								<span className="span__style">
									<label className="title__label">Categoria</label>
									<label className="value__label">{data?.category_name}</label>
								</span>

								<span className="span__style">
									<label className="title__label">ID del servicio</label>
									<label className="value__label">{data?.service_number}</label>
								</span>

								<Divider style={{ margin: '14px 0 0 0' }} />


								<div className="client__data">
									<div style={{ width: '100%' }}>
										<SubtitleBlue value="Datos del cliente" />
									</div>
									<div className="description__client">
										<span className="span__style">

											<label className="titlle__client">Nombre completo</label>
											<label className="text__description">{data?.user_full.first_name} {data?.user_full.last_name}</label>

										</span>

										<span className="span__style">

											<label className="titlle__client">Documento de identidad</label>
											<label className="text__description">RUT {data?.user_full.document_number}</label>
										</span>
									</div>

									<div className="description__clients">
										<span className="span__style">

											<label className="titlle__client">Correo electrónico</label>
											<label className="text__description">{data?.user_full?.email}</label>

										</span>

										<span className="span__style">

											<label className="titlle__client">Número de teléfono</label>
											<label className="text__description">{prefixes} {data?.user_full?.phone}</label>
										</span>
									</div>


									<span className="span__direction" >

										<label className="titlle__client">Dirección</label>
										<label className="text__description">
											{data?.has_oraculo === true ? data?.user_full?.direction : `${data?.user_full?.direction} ${data?.user_full?.direction_complementary}`}
										</label>
									</span>


								</div>
							</div>
						</div>

						<div className="service__detail__right">
							<div className="status__reagendar">

								<label className="label__ticket">{data?.status}</label>

								{
									rolPermission("change_os") === true ? (
										<div>
											{showReagendarButton && (
												<button onClick={onReagendar} className="btn">
													<i className="fa-solid fa-calendar-days"></i>
													<span>Reagendar</span>
												</button>
											)}
										</div>
									) : null
								}
							</div>
							<div className="select__container">

								<label className="label__select">Técnico principal</label>

								<Select
									style={{ width: "100%", background: changeTechnician === false ? "#f5f5f5" : "white", userSelect: changeTechnician === false ? "none" : "auto" }}
									onChange={onTechnicianValueChange}
									placeholder={technicanOS}
								>
									{technicians === null || technicians === undefined ? (
										<Select.Option style={{ userSelect: "none", color: "silver" }}>No hay técnicos disponibles</Select.Option>
									) : null}

									{technicians?.map(item => (
										<Select.Option key={item.id_technician} value={item.id_technician}>{item?.name}</Select.Option>))}
								</Select>

								<label className="text__information" >* El listado de técnicos que se muestra sera en función a la disponibilidad de la zona y el horario laboral</label>

								<Br />

								<div>
									<p className="text__assistant" >¿Asignar auxiliares?</p>
									<p className="text__description">No</p>
									<Switch
										style={{ margin: "0 5px" }}
										checked={assistant}
										onChange={onChange}
									/>
									<p className="text__description">Si</p>
								</div>

								<Br />

								{assistant === true && (
									<div style={{ marginTop: 15 }}>
										<label className="label__select">Auxiliares</label>
										<ReactSelect
											placeholder="Selecciona un técnico"
											isMulti
											options={AssistantForSelect}
											onChange={handleSelectChange}
											styles={customStyles}
											value={valueAssistant}
										/>
									</div>
								)}

								<Br />

								<label className="label__select">Status</label>
								<Select
									style={{
										width: '100%',
										height: 40,
										marginBottom: '2%',
										background: changeStatus === false ? "#f5f5f5" : "white",
										userSelect: changeStatus === false ? "none" : "auto"
									}}
									onChange={onStatusValueChange}
									value={statusID === statusId ? statusOs : statusID}
								>
									{allAvailableStatus?.map((item, i) => (
										<Select.Option key={i} value={item.value}>{item?.name}</Select.Option>
									))}
								</Select>

								<Br />

								<div className="row justify-end">
									<Space>
										{data?.status_id !== updateData.status || data.technician_id !== updateData.technician || btnSave ? (
											<BottomSection
												onAcept={patchOS}
												onCancel={returnPrevStateOnClose}
												background={'#F6F6F6'}
												minHeight={'29px'}
											/>
										) : null}
									</Space>


								</div>
								<Br />
							</div>
						</div>
					</div>

				</Container>) : null}

			{reagendar === true ? (
				< RescheduleCalendar
					setReagendar={setReagendar}
					reagendar={reagendar}
					ostypeName={data?.ostype.name}
					storedOperatorID={storedOperatorID}
					osID={data?.id_unico}
					data={data}
				/>
			) : null}
		</>
	)
}

export default OrdersServicieGeneralPanel

const Container = styled.div`

background-color: white;
display: flex;
flex-flow: column;
box-sizing: border-box !important;
padding: 0px 30.56px 17px 30.25px;
height: 100%;

.ant-select-disabled:where(.css-dev-only-do-not-override-w8mnev).ant-select:not(.ant-select-customize-input) .ant-select-selector {
	color: rgba(0, 0, 0, 0.25);
	background: rgba(0, 0, 0, 0.04);
	cursor: not-allowed;
	height: 40px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	width: 100%;
	height: 40px;
	padding: 0 11px;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
	border: 1px solid #265985;
	display: flex;
	max-height: 100%;
	min-height: 20px;
	flex-flow: column wrap;
	align-items: flex-start;
	overflow: hidden;
	position: absolute;
	padding-left: 15px;
	background: #2B80FF;
	cursor: pointer;
	:hover{
		background: #0060F8;
	}
}

.row{
	display: flex;
	align-items: center;
	padding: 0 !important;
	margin: 0 !important;
}

.btn{
	display: flex;
	align-items: center;
	justify-content: center;
	height: ${smallSizes.detailGeneral.heightButton};
	width: ${smallSizes.detailGeneral.widthButton};
	border: 1px solid #0060FF;
	border-radius: 3px;
	background: #DDE4EB;
}

.fa-calendar-days{
	color: #2B80FF;
	margin-right: 6.04px;
	font-size: ${smallSizes.fontSize.fontSize11};
}

.btn-reagendar{
	font-size: ${smallSizes.fontSize.fontSize12};
}

.space-between{
	justify-content: space-between;
	align-items: center;
}

.justify-center{
	justify-content: center;
}

.justify-end{
	justify-content: flex-end;
}

.align-center{
	align-items: center;
}

.blue{
	color: #2B80FF;
	padding: 0 !important;
	margin: 0 !important;
}

.identity_type{
	width: 124px;
	height: 40px;
	padding: 1em;
	border-radius: 5px;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.identity_number{
	width: calc(98% - 124px);
	height: 40px;
	padding-top: .3em;
	border-radius: 5px;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.phone_country{
	width: 90px;
	height: 40px;
	padding: 1em;
	border-radius: 5px;
}

.phone_number{
	width: calc(96% - 90px);
	height: 40px;
	padding-top: .3em;
	border-radius: 5px;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.phoneFlagsSelect{
	width: 84px;
	height: 40px;
	background: #f5f5f5f5;
	border-radius: 5px;
	border: 1px solid #d9d9d9;
	display : flex;
	justify-content: center;
	align-items: center;
}

.input{
	font-size: ${smallSizes.fontSize.fontSize12};
}

.prefixes{
	color: #868D96;
	font-size: ${smallSizes.fontSize.fontSize12};
	margin-left: 8px;
}

.text-technical{
	font-size: ${smallSizes.fontSize.fontSize10};
	color: #868D96;
}

.button-cancelar{
	width: 83px;
	height: 34px;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.rbc-button-link {
	font-size: ${smallSizes.fontSize.fontSize15};
}

.rbc-day-slot .rbc-event-label {
	font-size: ${smallSizes.fontSize.fontSize12};
}

.rbc-day-slot .rbc-event-content {
	font-size: ${smallSizes.fontSize.fontSize12};
}

.rbc-time-slot .rbc-label{
	font-size: ${smallSizes.fontSize.fontSize12}
}

//-----------------------------------------------------

.distribution__container{
	display: flex;
	justify-content: space-between;
	margin-top: 20.87px;
}

.service__detail__left{
	width: 45%;
	display: flex;
	flex-direction: column;
}

.service__detail{
	display: flex;
	justify-content: space-between;
	width: 100%;
	flex-wrap: wrap;
  gap: 22px;
}

.date__scheduling{
	font-size:${smallSizes.fontSize.fontSize12};
	color: #959595;
	margin-top: -10px;
}

.status__label{
	padding: 4.5px 24px 4.5px 24px;
	background: #E9F4FF;
	font-size: ${smallSizes.fontSize.fontSize22};
	font-weight: bold;
	border-radius: 2px;
	width: 180px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	box-sizing: border-box;
}

.service__detail__right{
	width: 47%;
	background: #F6F6F6;
}

.span__style{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.title__label{
	font-size: ${smallSizes.fontSize.fontSize12};
	margin-bottom: 0 !important;
	font-weight: bold;
}

.value__label{
	color: #959595;
}

.titlle__client{
	font-size: ${smallSizes.fontSize.fontSize12};
	color: #959595;
	font-weight: bold;
}

.client__data{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.description__client{
	width: 85%;
	display: flex;
	justify-content: space-between;
}

.description__clients{
	width: 80%;
	display: flex;
	justify-content: space-between;
}

.span__direction{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 80%;
}

//-----------------------------------
.status__reagendar{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 6% 6% 2% 0;
}

.label__ticket{
	font-size: ${smallSizes.fontSize.fontSize15};
	color: white;
	font-weight: bold;
	background: #2B80FF;
	padding: 6.5px 15px 6.5px 22px;
	border-radius: 0 3px 3px 0;
}
.select__container{
	width: 88%;
	margin-left: 6%;
}

.label__select{
	color: #131523;
	font-size: ${smallSizes.fontSize.fontSize12};
}

.text__information{
	font-size: ${smallSizes.fontSize.fontSize8};
	color: rgb(149, 149, 149);
	margin-left: 2px;
	margin-top: 16px;
}

.text__assistant{
	font-size: ${smallSizes.fontSize.fontSize12};
	font-weight: bold;
	margin-right: 25px;
}

.text__description{
	font-size: ${smallSizes.fontSize.fontSize12};
}

@media screen and (min-width: ${devices.smallDesk}){
	.btn-reagendar{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.fa-calendar-days{
		font-size: ${smallSizes.fontSize.fontSize11};
	}

	.btn{
		height: ${smallSizes.detailGeneral.heightButton};
		width: ${smallSizes.detailGeneral.widthButton};
	}

	.input{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.identity_type{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.identity_number{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.prefixes{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.phone_number{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.text-technical{
		font-size: ${smallSizes.fontSize.fontSize10};
	}

	.button-cancelar{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.rbc-button-link {
		font-size: ${smallSizes.fontSize.fontSize15};
	}

	.rbc-day-slot .rbc-event-label {
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.rbc-day-slot .rbc-event-content {
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.rbc-time-slot .rbc-label{
		font-size: ${smallSizes.fontSize.fontSize12}
	}

	//-------------------------------merge------------------------------------

	.date__scheduling{
		font-size:${smallSizes.fontSize.fontSize12};
	}

	.status__label{
		font-size: ${smallSizes.fontSize.fontSize22};
	}

	.title__label{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.titlle__client{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	//-------------------------------------------------

	.label__ticket{
		font-size: ${smallSizes.fontSize.fontSize15};
	}

	.label__select{
		font-size: ${smallSizes.fontSize.fontSize12};
	}
	
	.text__information{
		font-size: ${smallSizes.fontSize.fontSize8};
	}
	
	.text__assistant{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

	.text__description{
		font-size: ${smallSizes.fontSize.fontSize12};
	}

}

//-----------------------------------------------------

@media screen and (min-width: ${devices.mediumDesk}){
	.btn-reagendar{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.fa-calendar-days{
		font-size: ${mediumSizes.fontSize.fontSize11};
	}

	.btn{
		height: ${mediumSizes.detailGeneral.heightButton};
		width: ${mediumSizes.detailGeneral.widthButton};
	}

	.input{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.identity_type{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.identity_number{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.prefixes{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.phone_number{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.text-technical{
		font-size: ${mediumSizes.fontSize.fontSize10};
	}

	.button-cancelar{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.rbc-button-link {
		font-size: ${mediumSizes.fontSize.fontSize15};
	}

	.rbc-day-slot .rbc-event-label {
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.rbc-day-slot .rbc-event-content {
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.rbc-time-slot .rbc-label{
		font-size: ${mediumSizes.fontSize.fontSize12}
	}

	//-------------------------------merge------------------------------------

	.date__scheduling{
		font-size:${mediumSizes.fontSize.fontSize12};
	}

	.status__label{
		font-size: ${mediumSizes.fontSize.fontSize22};
	}

	.title__label{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.titlle__client{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	//-------------------------------------------------

	.label__ticket{
		font-size: ${mediumSizes.fontSize.fontSize15};
	}

	.label__select{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
	
	.text__information{
		font-size: ${mediumSizes.fontSize.fontSize8};
	}
	
	.text__assistant{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}

	.text__description{
		font-size: ${mediumSizes.fontSize.fontSize12};
	}
}

//-----------------------------------------------------

@media screen and (min-width: ${devices.largeDesk}){
	.btn-reagendar{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.fa-calendar-days{
		font-size: ${largeSizes.fontSize.fontSize11};
	}

	.btn{
		height: ${largeSizes.detailGeneral.heightButton};
		width: ${largeSizes.detailGeneral.widthButton};
	}

	.input{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.identity_type{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.identity_number{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.prefixes{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.phone_number{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		font-size:${largeSizes.fontSize.fontSize12};
	}

	.text-technical{
		font-size: ${largeSizes.fontSize.fontSize10};
	}

	.button-cancelar{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.rbc-button-link {
		font-size: ${largeSizes.fontSize.fontSize15};
	}

	.rbc-day-slot .rbc-event-label {
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.rbc-day-slot .rbc-event-content {
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.rbc-time-slot .rbc-label{
		font-size: ${largeSizes.fontSize.fontSize12}
	}

	//-------------------------------merge------------------------------------

	.date__scheduling{
		font-size:${largeSizes.fontSize.fontSize12};
	}

	.status__label{
		font-size: ${largeSizes.fontSize.fontSize22};
		width: 195px;
	}

	.title__label{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.titlle__client{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	//-------------------------------------------------

	.label__ticket{
		font-size: ${largeSizes.fontSize.fontSize15};
	}

	.label__select{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
	
	.text__information{
		font-size: ${largeSizes.fontSize.fontSize8};
	}
	
	.text__assistant{
		font-size: ${largeSizes.fontSize.fontSize12};
	}

	.text__description{
		font-size: ${largeSizes.fontSize.fontSize12};
	}
}
`;
