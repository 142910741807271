/* eslint-disable */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { getCatgeory, getGalleryImage } from '../../../../api/imageGallery';
import UploadImageForm from '../gallery/uploadImageForm/UploadImageForm';

import EditImageForm from './editImageForm/EditImageForm';
import CustomTable from './customTable/CustomTable';
import { devices, largeSizes, mediumSizes, smallSizes } from '../../../../helpers/sizes';

import style from './galleryPanel.module.css'
import { BarChart, Bar, XAxis, YAxis } from 'recharts';

export default function GalleryPanel({ data }) {

	const [visibleEditForm, setVisibleEditForm] = useState(false)
	const [visibleUploadForm, setVisibleUploadForm] = useState(false)

	const [ROWS, setROWS] = useState(null)
	const [selectedInfo, setSelectedInfo] = useState(null)

	const [listCategory, setListCategory] = useState(null)

	const [numberRequired, setNumberRequired] = useState(null)
	const [arrayMatch, setArrayMatch] = useState(0)
	const [categoryMatch, setCategoryMatch] = useState({})

	const IDdeOS = data?.id_unico;

	const categoryId = data?.category_id


	const onEditForm = (item) => {
		setSelectedInfo(item)
		setVisibleEditForm(true)
	}

	const openUploadForm = () => setVisibleUploadForm(true)
	const closeUploadForm = () => setVisibleUploadForm(false)

	const dataGraph = [
		{ name: 'cantidad', uv: arrayMatch },
	];

	useEffect(() => {
		IDdeOS && getGalleryImage(IDdeOS).then(res => setROWS(res?.response))
		categoryId && getCatgeory(categoryId).then(res => {
			setListCategory(res.imgs.value)
			setNumberRequired(res.imgs.value.length)
		})
	}, [])

	useEffect(() => {
		if (listCategory && ROWS) {

			const coincidencias = {};
			const coincidenciasArray = [];

			listCategory.forEach(item1 => {
				let cantidad = 0;
				ROWS.forEach(item2 => {
					if (item1.img_name.trim() === item2.name.trim()) {
						cantidad++;
					}
				});

				if (cantidad > 0) {
					coincidencias[item1.img_name] = cantidad;
				}
			});

			Object.keys(coincidencias).forEach(nombre => {
				coincidenciasArray.push({
					nombre,
					cantidad: coincidencias[nombre]
				});
			});

			setCategoryMatch(coincidencias);
			setArrayMatch(coincidenciasArray.length);
		}
	}, [listCategory, ROWS])

	useEffect(() => {

	}, [])


	return (
		<Container>
			<section className={style.container_header}>
				<p className='text-gallery-description'>Listados de imágenes asociadas a la categoría de la orden de servicio.</p>

				<div>
					<button
						className={style.button_create}
						onClick={openUploadForm}
					>
						Agregar imagen
					</button>
				</div>
			</section>

			<section className={style.container_graph}>
				<BarChart width={400} height={30} data={dataGraph} layout="vertical">
					<XAxis type="number" hide domain={[0, numberRequired]} />
					<YAxis dataKey="name" type="category" hide />

					<Bar dataKey="uv" fill="#2B80FF" barSize={7} radius={[10, 10, 10, 10]} background={{ fill: '#eee', radius: 10 }} />
				</BarChart>

				<p className={style.text_p}>{`${arrayMatch} de ${numberRequired} imágenes requeridas cargadas`}</p>
				<p className={style.text_blue}>Maximo 5 imágenes por item</p>
			</section>

			<CustomTable
				onEditForm={onEditForm}
				row={ROWS}
			/>

			{/* Form para Subir */}
			{visibleUploadForm &&
				<UploadImageForm
					ID={IDdeOS}
					selectedInfo={selectedInfo}
					closeUploadForm={closeUploadForm}
					listCategory={listCategory}
					categoryMatch={categoryMatch}
				/>
			}

			{/* Form para editar */}
			{visibleEditForm &&
				<EditImageForm
					setVisibleEditForm={setVisibleEditForm}
					selectedInfo={selectedInfo}
					listCategory={listCategory}
					categoryMatch={categoryMatch}
				/>
			}
		</Container>
	)

}

const Container = styled.div`
  width: 100%;
	margin-top: 21px;


  .text-gallery-description{
    color: #5A607F;
    font-size: ${smallSizes.fontSize.fontSize14};
    padding-left: 48px !important;
		margin: 0 !important;
  }
  .img{
    width: 116px;
    height: 76px;
  }
  .date{
    font-size: 16px;
    color: #2B80FF;
    font-weight: 600;
  }
  .blue_text{
    font-size: 13px;
    color: #2B80FF;
    text-decoration: none;
  }

	@media screen and (min-width: ${devices.smallDesk}){
		.text-gallery-description{
			font-size: ${smallSizes.fontSize.fontSize14};
		}
	}

	@media screen and (min-width: ${devices.mediumDesk}){
		.text-gallery-description{
			font-size: ${mediumSizes.fontSize.fontSize14};
		}
	}

	@media screen and (min-width: ${devices.largeDesk}){
		.text-gallery-description{
			font-size: ${largeSizes.fontSize.fontSize14};
		}
	}
`