
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";

import { Panel } from "../../../../components/panel/panel.jsx";
import { Button, Input, Popconfirm, Table } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import Br from "../../../../components/br/Br.jsx";
import UseOperator from "../../../../hooks/UseOperator.jsx";
import { getCategoriesColumn, getCategoriesRow, patchColorData } from "../../../../api/ostypeCreate.js";
import SubtitleBlue from "../../../../components/text/drawer/subtitleBlue/index.jsx";
import LabelDrawer from "../../../../components/text/drawer/label/index.jsx";
import { permissionCheck } from "../../../../helpers/helpers.js";
import { useSelector } from "react-redux";
import SingleButton from "../../../../components/buttons/singleButton/index.jsx";

import style from "./overallDetailsScreen.module.css";
import ColorCircle from "../../../../components/colorCircle/index.jsx";
import AddCategoryForm from "../../newCategory/index.jsx";
import EditCategoryForm from "../EditCategoryForm.jsx";
import EditServiceOrder from "../editServiceOrderType/EditServiceOrder.jsx";


export default function OverallDetailsScreen({ data }) {
	const history = useHistory();
	const [showColorPicker, setShowColorPicker] = useState(false)
	const [newName, setName] = useState(data?.name)
	const [colorValuePicked, setColorPicked] = useState(data?.color);
	const [tag, setTag] = useState(data?.tag)

	const [editMode, setEditMode] = useState(false);

	const { storedOperatorID } = UseOperator()




	// Svg Icon
	const D = "M341.6 29.2L240.1 130.8l-9.4-9.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-9.4-9.4L482.8 170.4c39-39 39-102.2 0-141.1s-102.2-39-141.1 0zM55.4 323.3c-15 15-23.4 35.4-23.4 56.6v42.4L5.4 462.2c-8.5 12.7-6.8 29.6 4 40.4s27.7 12.5 40.4 4L89.7 480h42.4c21.2 0 41.6-8.4 56.6-23.4L309.4 335.9l-45.3-45.3L143.4 411.3c-3 3-7.1 4.7-11.3 4.7H96V379.9c0-4.2 1.7-8.3 4.7-11.3L221.4 247.9l-45.3-45.3L55.4 323.3z"

	const goToList = () => history.replace("/TypesOS");


	const onColorPick = () => setShowColorPicker(!showColorPicker)

	const onEditMode = () => {
		setEditMode(!editMode)
		setShowColorPicker(false)
		setName(data?.name)
		setColorPicked(data?.color);
		setTag(data?.tag)
	}

	const onColorChange = (e) => {
		setColorPicked(e)
		setShowColorPicker(!showColorPicker)
	}

	const newType = {
		"name": newName,
		"color": colorValuePicked,
		"tag": tag,
		"operator": storedOperatorID
	}

	const updateColor = () => patchColorData(data?.id_unico, newType, goToList);



	const onCancelEdit = () => {
		setEditMode(false)
		setShowColorPicker(false)
		setName(data?.name)
		setColorPicked(data?.color);
		setTag(data?.tag)
	}

	// modificacion

	const [COLUMNS, setCOLUMNS] = useState([]);
	const [ROWS, setROWS] = useState([]);

	const [addCategoryForm, setAddCategoryForm] = useState(false);
	const [editCategoryForm, setEditCategoryForm] = useState(false);
	const [editableData, setEditableData] = useState(null);

	const [editOrderType, setEditOrderType] = useState(false);

	const array = useSelector((state) => state?.authState?.permissions);

	const rolPermission = (value) => {
		return permissionCheck(array, value)
	}

	useEffect(() => {
		// data de la estructura de la columna y se agrega el onCell a la columna name
		getCategoriesColumn().then(res => {
			const data = res.map(item =>
				item.dataIndex === "name" ?
					{
						...item,
						onCell: (record) => {
							if (rolPermission("add_category")) {
								return {
									onClick: () => {
										setEditableData(record);
										setEditCategoryForm(true);
									},
									style: { cursor: "pointer" },
									className: "hover-table-class"
								}
							}
						}
					} : item
			)
			setCOLUMNS(data)
		})

		// rows de tabla
		getCategoriesRow(data?.id_unico).then(res => {
			setROWS(res)
		})
	}, [])
	return (
		<Panel>
			<section className={style.container_general}>
				<SubtitleBlue value="Información General" marginBottom="0px" />
				<span className={style.text_date}>Fecha de creación <b>{data?.created}</b></span>

				<section className={style.container_data}>
					<section className={style.container_description}>
						<div className={style.sub_container}>
							<p className={style.text_title}>Color</p>
							<span className={style.description}>
								<ColorCircle color={data?.color} />
								<p className={style.text_color}>{data?.color.toUpperCase()}</p>
							</span>
						</div>

						<div className={style.sub_container}>
							<p className={style.text_title}>Etiqueta</p>
							<p className={style.text_label}>{data?.tag}</p>
						</div>

						<div className={style.sub_container}>
							<p className={style.text_title}>Nombre</p>
							<p className={style.text_name} onClick={() => setEditOrderType(true)}>{data?.name}</p>
						</div>
					</section>
					{rolPermission("add_category") === true ? (
						<button className={style.button} onClick={() => setAddCategoryForm(!addCategoryForm)}>
							<i className={`fa-solid fa-plus ${style.icon_button}`}></i>
							Agregar categoría
						</button>
					) : null}


				</section>

			</section>
			<section className={style.conatiner_table}>
				<Table
					columns={COLUMNS}
					dataSource={ROWS}
					pagination={false}
				/>
			</section>

			{addCategoryForm &&
				<AddCategoryForm
					os_type={data?.id_unico}
					addCategoryForm={addCategoryForm}
					setAddCategoryForm={setAddCategoryForm}
				/>
			}

			{editCategoryForm &&
				<EditCategoryForm
					editableData={editableData}
					os_type={data?.id_unico}
					editCategoryForm={editCategoryForm}
					setEditCategoryForm={setEditCategoryForm}
				/>
			}
			{editOrderType &&
				<EditServiceOrder
					setEditOrderType={setEditOrderType}
					data={data}
				/>
			}

		</Panel>
	);
}

const SubCont1 = styled.div`
	background-color: white;
	display: flex;
	flex-flow: column;
	padding: 0 35px 20.19px 30.25px;

	p,h3, span, div, b{
		margin: inherit;
		display: inline;
	}
	p{
		display: inline;
	}
	
	.row {
		margin: inherit;
		display: flex;
		align-items: center;
	}
	.column {
		display: flex;
		flex-flow: column;
	}
	
	.space-between {
		display: flex;
		justify-content: space-between;
	}
	.center {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.flex-end {
		display: flex;
		justify-content: end;
	}
	.title {
		color: #2B80FF;
		font-size: 14px; 
		font-weight: bold; 
	}
	.b {
		color: #2B80FF;
	}
	.left, .right {
		width: 49%
	}
	.inputContainer {
		width: 100%;
		height: 40px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: 5px;
		border: 1px solid #E0E0E0;
		position: relative;
	}
	.inputContainer .selected_color {
		width: calc(99% - 40px);
		height: 40px;
		padding: 1em;
		display: flex;
		align-items: center;
	}

	.inputContainer .selected_color .circle{
		width: 10px;
		height: 10px;
		border-radius: 100%;
		margin-right: 10px;
	}
	.inputContainer .colorPickerIcon, .inputContainer .colorPickerIcon svg {
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.inputContainer .colorPickerIcon svg {
		width: 15px;
		height: 15px;
	}
	.inputColor {
		position: absolute;
	}
	.btn__ {
		width: 83px;
		height: 40px;
	}
	
	.btn__edit {
		width: 101px;
		height: 40px;
		border: 2px solid #0060FF;
	}
	
`;
