import React from 'react'
import { myFetchGet } from "../services/services";
import DatatableRowText from '../components/text/datatable/datatableRowText/index.jsx';
import DatatableHeaderText from '../components/text/datatable/datatableHeaderText';
import ColorCircle from '../components/colorCircle';

const getDatatables_structColumns = async () => {
	try {
		const data = await myFetchGet("api/v1/os/datatables_struct");
		const columns = [];
		const columnsTittles = Object.keys(data.columns);
		columnsTittles.forEach((columnsTittle) => {
			columns.push({
				sorter: columnsTittle === "ID" ? true : null,
				width:
					columnsTittle === "ID" ? "70px"
						: columnsTittle === "Fecha" ? "110px"
							: columnsTittle === "Doc de Identidad" ? "15%"
								: columnsTittle === "Status" ? "180px"
									: "",
				dataIndex: data.columns[columnsTittle].field,
				key: data.columns[columnsTittle].field,
				align: 'left',
				title: <DatatableHeaderText value={columnsTittle} />,
				filterMultiple: false,
				filters:
					columnsTittle === 'Status' ?
						[
							{
								text: "Agendada",
								value: 0,
							},
							{
								text: "Cancelada",
								value: 5,
							},
							{
								text: "Finalizada",
								value: 8,
							},
							{
								text: "En desplazamiento",
								value: 1,
							},
							{
								text: "En atendimiento",
								value: 2,
							},
							{
								text: "En espera del cliente",
								value: 4,
							},
							{
								text: "Por reagendar",
								value: 7,
							},
							{
								text: "Pausada",
								value: 3,
							},
							{
								text: "No se pudo instalar",
								value: 6,
							},
						] : null,
				render: columnsTittle === "ID"
					? (value) => <span style={{ display: "flex", alignItems: "center" }}>
						<DatatableRowText value={value} color='#2B80FF' bold={true} />
					</span>

					: columnsTittle === "Fecha" ?
						(value) =>
							<span style={{ display: "flex", alignItems: "center" }}>
								<DatatableRowText value={value} />
							</span>
						: columnsTittle === "Tipo" ?
							(value) =>
								<span style={{ display: "flex", alignItems: "center" }}>
									<ColorCircle color={value?.color} />
									<DatatableRowText value={value?.name} />
								</span>
							: columnsTittle === "Status" ?
								(value) =>
									<span style={{ display: "flex", alignItems: "center" }}>
										<DatatableRowText value={value} color='#2B80FF' />
									</span>

								: (value) =>
									<span style={{ display: "flex", alignItems: "center" }}>
										<DatatableRowText value={value} />
									</span>
			});
		});
		return columns;

	} catch (e) { }
}

export { getDatatables_structColumns }